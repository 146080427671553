import React, { useState, useEffect, useRef, useContext } from 'react'
import { Button, message, Modal, Select, Spin, Form, Col, Row, Input, DatePicker, Checkbox, Tooltip, Image, Tabs } from 'antd'
import {
    getOrderOne,
    getOrderImg,
    editOrder,
    orderRepair,
    lookMaintainer,
    orderCancelReview,
    orderReturnReq,
    orderReturnApproved,
} from '@/service/ordersService'
import { getCustomerListNew, getVehicleList, newGetList, getVehicleOne, getCustomerOneNew } from '@/service/customerService'
import { getUserInfo, getPcodeDict, getBentityListOption } from '@/utils/dataStorage'
import { getToken } from '../../utils/dataStorage'
// import { getTeamList } from "@/service/teamService";
// import DispatchOrder from "./dispatchOrder" //派单框
import Maintenance from './maintenance' //维修项目
import Material from './material' //物料
import LookCommentAndPayment from './lookComment' //支付和评价查看
// import InsertAddressMap from './insertAddressMap' //地图
import InsertAddressMap from '@/components/TMapChoose' //地图
import OrderCustomerEdit from './orderCustomerEdit' //编辑页客户信息编辑弹框
import OrderCustomerAdd from './OrderCustomerAdd'
import AddVehicleRecord from '../customerManage/vehicleRecord/vehiclePopupDetail'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { MyContext } from '@/store/myContext'
// import { getUserIdInfo } from "@/utils/requestMenu"
import { timeShift } from '@/utils/util'
import { fileLookUrl } from '@/config/app.js'
import ModifyContactName from './modifyContactName'
// import Sanbao from "./sanbao"
import OrderDispatch from './orderDispatch'
import RepairHistory from './repairHistory'
import RepairTemplate from './repairTemplate'
import TaskBook from './taskBook/taskBook'
import CaiAiFu from './caiAiFu'
import SanBaoList from './sanbaoList'
// import RepairTemplateDetail from "@/pages/repairTemplate/repairTemplateDetail"
import RepairTemplateDetail from '../dataManage/repairTemplate/repairTemplateDetail'
import OtherProJect from './otherProject'
import { OperatorUserType, OrderReturnStatus } from '@/utils/enum.js'
import { getBussinessEntityList } from '@/service/dataService'
import ReviewOrder from './reviewModal'
import ReviewOrdersModal from './reviewOrders/reviewModal'
import './orderDetail.less'
import { Provider } from './context'
import CombinationList from './combinationList'
import { SettleDrawer } from './settleDrawer'
import { useSettleModel } from './settleModel'
import { useDictModel } from '@/erp_subpackage/pages/base/dict/dictModel'
import { DictCodeEnmu } from '@/erp_subpackage/utils/DictCodeEnum'
import TextPriviewImage from './TextPriviewImage'
import { invoiceModel } from './InvoiceModel'
import ImgModal from './imgModal'
import { getUserOrgan } from '@/utils/dataStorage'
import { useOrderModel } from './orderModal'
import { ItemAttr } from '@/utils/enum'
import { IsFranchisees } from '@/utils/common'
const ItemAttraArr = Object.entries(ItemAttr)
const { Option } = Select
const { TabPane } = Tabs
let saveTimer = null

const OrderDetails = (props) => {
    const { apiCount } = useOrderModel()
    const { imageUrl } = invoiceModel()
    const [orderForm] = Form.useForm()
    const { getSelectedDictOne, getTreeNodeName } = useDictModel()
    const { dispatch, state, detailsPage } = useContext(MyContext)
    const { ClientDetails, UserDetails, VehicleDetails } = detailsPage
    const { modeKey, mode, rowInfo, setPrintVisible, orderStatus, parentModeKey = 'ordersManage' } = props
    const [modifyContactName, setModifyContactName] = useState(false)
    // const [showSanbao, setShowSanbe] = useState(false) //展示三包信息
    const [showRepairTemplateDetail, setShowRepairTemplateDetail] = useState(false) //展示生成模版
    const [repairTemplateData, setRepairTemplateData] = useState(null) //维修模版内容
    const maintenanceRef = useRef() //维修项目 子组件
    const materialRef = useRef() //维修物料  子组件
    const otherProjectRef = useRef() //其他项目  子组件
    const caiAiFuRef = useRef() //采埃孚  子组件
    const [mapVisible, setMapVisible] = useState(false) //地图
    const [saveMapAddr, setSaveMapAddr] = useState(null) //地图经纬度
    const [reviewVisible, setReviewVisible] = useState(false)
    const { setSettleVisible, getSettleInfo } = useSettleModel()
    const [isLoading, setIsLoading] = useState(false)
    const [isCancelReviewStatus, setIsCancelReviewStatus] = useState(false)
    //派单
    const [showOrderDispatchVisible, setShowOrderDispatchVisible] = useState(false)
    const [customerInfo, setCustomerInfo] = useState({
        //客户列表
        selectValue: {},
        data: [],
        fetching: false,
    })
    const [vehicleInfo, setVehicleInfo] = useState({
        //车辆列表
        data: [],
        fetching: false,
    })
    const [userInfo, setUserInfo] = useState({
        //用户列表
        data: [],
        fetching: false,
    })
    const [teamInfo, setTeamInfo] = useState({
        //团队列表
        data: [],
        fetching: false,
    })
    const [entityData, setEntityData] = useState({
        //所属实体信息
        data: [],
        fetching: false,
    })
    // const [dispatchVisible, setDispatchVisible] = useState({
    //     //派单弹框
    //     visible: false,
    //     orderId: "",
    // })
    const [reviewCancelVisible, setReviewCancelVisible] = useState(false)
    const [orderStatusDisabled, setOrderStatusDisabled] = useState(false) //工单的状态，已取消的不可保存
    const [saveLoading, setSaveLoading] = useState(false) //保存按钮
    const [receivableMonye, setReceivablesMonye] = useState({
        itemAccount: 0,
        material: 0,
        otherAccount: 0,
    }) //每次变动计算表单实收金额
    const [orderStateSystem, setOrderStateSystem] = useState(false) //编辑时工单状态是否是审核
    const [imgLookData, setImgLookData] = useState({ visible: false, repairMode: '' })
    //工单支付和查看
    const [lookVisible, setLookVisible] = useState({
        visible: false,
        taskMode: '',
    })
    //选中的标签栏
    const [actTabs, setActTabs] = useState('1')

    //客户在编辑页修改信息
    const [orderEditCustomerVisible, setOrderEditCustomerVisible] = useState(false)
    const [orderAddCustomerVisible, setOrderAddCustomerVisible] = useState(false)
    const [addVehicleRecordVisible, setAddVehicleRecordVisible] = useState(false)
    //客户修改成功
    const editCustomerSuccess = (obj) => {
        setCustomerInfo({
            selectValue: {
                customerName: obj.customerName,
                customerId: obj.customerId,
            },
            data: [{ text: obj.customerName, value: obj.customerId }],
            fetching: false,
        }) //客户列表回显
        orderForm.setFieldsValue({
            customerId: obj.customerId,
            customerType: obj.customerType,
        })
    }
    //监听全局的的车辆信息变化， 当有数据和当前编辑详情key一样。  重新回显车辆信息， 和所编辑的客户和车辆
    useEffect(() => {
        if (state.vehicleInfo && state.activeMenu === modeKey) {
            let obj = state.vehicleInfo
            // console.log(obj);
            orderForm.setFieldsValue({
                ...obj,
            })
            setCustomerInfo({
                selectValue: {
                    customerName: obj.customerName,
                    customerId: obj.customerId,
                },
                data: [{ text: obj.customerName, value: obj.customerId }],
                fetching: false,
            }) //客户列表回显
            setVehicleInfo({
                data: [
                    {
                        text: obj.customerVehicleCode,
                        value: obj.customerVehicleId,
                    },
                ],
                fetching: false,
            }) //车辆列表回显
        }
    }, [state]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (mode === 'insert') {
            // getAddressLocation(getUserOrgan().organAddress)
            //     .then((res) => {
            //         if (res.status === 200 && res.data.info === 'OK' && res.data.infocode === '10000' && res.data.geocodes.length > 0) {
            //             let addrObj = res.data.geocodes[0]
            //             let lonAndlat = addrObj.location.split(',') //经度 维度
            //             orderForm.setFieldsValue({
            //                 repairAddress: addrObj.formatted_address,
            //             })
            //             setSaveMapAddr({
            //                 //地图初始信息
            //                 name: addrObj.formatted_address,
            //                 addr: addrObj.formatted_address,
            //                 longitude: lonAndlat[0],
            //                 latitude: lonAndlat[1],
            //             })
            //         } else {
            //             orderForm.setFieldsValue({
            //                 repairAddress: undefined,
            //             })
            //             setSaveMapAddr(null)
            //         }
            //     })
            //     .catch(() => {
            //         orderForm.setFieldsValue({
            //             repairAddress: undefined,
            //         })
            //         setSaveMapAddr(null)
            //     })
            const { organAddress, organLat, organLng } = getUserOrgan()
            if (organAddress && organLat && organLng) {
                setSaveMapAddr({
                    //地图初始信息
                    name: organAddress,
                    addr: organAddress,
                    longitude: organLng,
                    latitude: organLat,
                })
                orderForm.setFieldsValue({
                    repairAddress: organAddress,
                })
            } else {
                orderForm.setFieldsValue({
                    repairAddress: undefined,
                })
                setSaveMapAddr(null)
            }
        }

        isMode()
        getBussinessEntity()
        return () => {
            clearTimeout(saveTimer)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //应收金额 不会跟这工单得优惠打折变动
        let all = (receivableMonye.itemAccount + receivableMonye.material + receivableMonye.otherAccount).toFixed(2)
        orderForm.setFieldsValue({
            orderTotalAmount: all,
        })
        countTotal(all)
    }, [receivableMonye]) // eslint-disable-line react-hooks/exhaustive-deps
    //计算表单的实收金额
    const onReceivables = (obj) => {
        //配件总金额变动
        if (obj.itemAccount === null) {
            setReceivablesMonye({
                ...receivableMonye,
                material: obj.material,
            })
        }
        //项目总金额变动
        if (obj.material === null) {
            setReceivablesMonye({
                ...receivableMonye,
                itemAccount: obj.itemAccount,
            })
        }

        if (obj.otherAccount) {
            setReceivablesMonye({
                ...receivableMonye,
                otherAccount: obj.otherAccount,
            })
        }
    }
    //折扣和预付款，优惠金额失焦的时候触发
    const countTotal = (num) => {
        let arr = orderForm.getFieldsValue(['orderDiscount', 'prepayments', 'preferentialAmount', 'orderTotalAmount'])
        let orderDiscount = 0 //折扣
        let prepayments = 0 //预付款
        let preferentialAmount = 0 //优惠金额
        let orderActualAmount = num ? num : arr.orderTotalAmount ? +arr.orderTotalAmount : 0 // 当应收金额变动时，用传过来的， 否则获取应收的金额重新算。
        if (arr.orderDiscount && /^[1-9]?(\.\d)?$/.test(arr.orderDiscount)) {
            orderDiscount = +arr.orderDiscount
        }
        if (arr.prepayments && /^\d+(\.\d{0,2})?$/.test(arr.prepayments)) {
            prepayments = +arr.prepayments
        }
        if (arr.preferentialAmount && /^\d+(\.\d{0,2})?$/.test(arr.preferentialAmount)) {
            preferentialAmount = +arr.preferentialAmount
        }
        // console.log(orderDiscount, prepayments, preferentialAmount, orderActualAmount);
        // 计算公式：  应收 * 折扣 -优惠金额  -预付款。
        if (orderDiscount) {
            orderForm.setFieldsValue({
                orderActualAmount: ((orderActualAmount * orderDiscount) / 10 - prepayments - preferentialAmount).toFixed(2),
            })
        } else {
            orderForm.setFieldsValue({
                orderActualAmount: (orderActualAmount - prepayments - preferentialAmount).toFixed(2),
            })
        }
    }
    const [orderStatusType, setOrderStatusType] = useState('--') //记录下工单的状态
    const [orderCustomerName, setOrderCustomerName] = useState('--') //记录下客户名称
    const [orderVehicleCode, setOrderVehicleCode] = useState('--') //记录下车辆牌照
    const [orderCode, setOrderCode] = useState('--') //记录下工单编号
    const [isOrderPricePay, setisOrderPricePay] = useState(false) //工单是否已经支付

    //工单类型时候是否是三包，  默认没有选中
    const [orderTypeIsThree, setOrderTypeIsThree] = useState(false)
    const [orderType, setOrderType] = useState('')
    //工单类型选择事件   当选择三包时候，  物料和维修项目的所有单价 数量 金额 折扣 全部归零， 订单的优惠， 折扣 预付款归0。
    const handleOrderTypeChange = (value) => {
        setOrderType(value)
        if (value === '014002') {
            setOrderTypeIsThree(true)
            orderForm.resetFields(['prepayments', 'preferentialAmount', 'orderDiscount'])
        } else {
            setOrderTypeIsThree(false)
        }

        //利用react单详数据流，  orderTypeIsThree判断上次是否是选中三包
        materialRef.current.orderTypeChange(value, orderTypeIsThree)
        maintenanceRef.current.orderTypeChange(value, orderTypeIsThree)
        otherProjectRef.current.orderTypeChange(value, orderTypeIsThree)
    }

    useEffect(() => {
        if (IsFranchisees()) {
            //如果是加盟商只能选择"云豪车服（北京）科技服务有限公司"
            let entityData = getBentityListOption()
            if (orderType === '014006') {
                entityData = entityData.filter((i) => i.value === 'bentity.bj-yh-auto-service')
                if (entityData.length < 1) {
                    setEntityData({
                        data: [],
                        fetching: false,
                    })
                    orderForm.setFieldsValue({
                        ciBusinessEntityId: '',
                        ciBusinessEntityName: '',
                    })
                    return message.error('该机构没有选择云豪车服（北京）科技服务有限公司成为销售可选实体')
                }
                orderForm.setFieldsValue({
                    ciBusinessEntityId: entityData[0].value,
                    ciBusinessEntityName: entityData[0].label,
                })
            }
            setEntityData({
                data: entityData,
                fetching: false,
            })
        }
    }, [orderType])
    //保存编辑回显的订单详情数据
    const [orderInfoOne, setOrderInfoOne] = useState({})
    //模式  反现数据
    const isMode = async () => {
        setIsLoading(true)
        if (mode === 'edit' || mode === 'look') {
            getOrderOne(rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                let results = res.retData
                //更新采埃孚数据
                caiAiFuRef.current.caiAiFuinit(results)
                setOrderInfoOne(results)
                setOrderType(results.orderType)
                setIsCancelReviewStatus(results.reviewerStatus)
                results.orderType === '014002' && setOrderTypeIsThree(true) //工单类型是三包 设置下。
                results.orderStatus === 'cancelled' && setOrderStatusDisabled(true) //已取消的工单不可保存
                results.orderStatus === 'system_review_fail' && setOrderStateSystem(true) //工单状态是待系统审核才展示 审核的按钮。
                if (
                    results.orderStatus === 'system_review_fail' ||
                    results.orderStatus === 'pending_system_review' ||
                    results.orderStatus === 'completed' ||
                    results.orderStatus === 'cancelled'
                ) {
                    setisOrderPricePay(true)
                }
                // results.orderStatus !== "lookMaintainer" && //工单状态不是待派工或者待出发的 回显维修人员
                //     results.orderStatus !== "pending_labor" &&
                lookMaintainer(rowInfo.id).then((res) => {
                    // if (res && res.retCode === 200) {
                    let str = '',
                        mainWeiXiuRenYuan = '' //要展示的维修人员
                    res.retData.forEach((item) => {
                        if (item.majorFlag === 1) {
                            mainWeiXiuRenYuan = item.userName
                        }
                        str += item.userName + ','
                    })
                    orderForm.setFieldsValue({
                        weiXiuRenYuan: str,
                        mainWeiXiuRenYuan,
                    })
                    // }
                })
                // if (results.billId) {
                // getUserIdInfo(results.billId ?? getUserInfo().id).then((res) => {
                //     if (res && res.retCode === "200") {
                //         orderForm.setFieldsValue({
                //             staffPhone: res.retData.staffPhone,
                //         })
                //     } else {
                //         message.warning("获取开单员电话失败")
                //     }
                // })
                // }
                // orderForm.setFieldsValue({
                //     staffPhone: getUserIdInfo(results.billId ?? getUserInfo().id)?.phone || "",
                // })
                setSaveMapAddr({
                    //地图初始信息
                    name: results.repairAddress,
                    addr: results.repairAddress,
                    longitude: results.repairLng,
                    latitude: results.repairLat,
                })
                orderForm.setFieldsValue({
                    ...results,
                    createTime: moment(results.createTime),
                    teamEstimatedArrivalTime: results.teamEstimatedArrivalTime ? moment(results.teamEstimatedArrivalTime) : results.teamEstimatedArrivalTime,
                    teamEstimatedFinishTime: results.teamEstimatedFinishTime ? moment(results.teamEstimatedFinishTime) : results.teamEstimatedFinishTime,
                    entryFactoryTime: results.entryFactoryTime ? moment(results.entryFactoryTime) : results.entryFactoryTime,
                    outFactoryTime: results.outFactoryTime ? moment(results.outFactoryTime) : results.outFactoryTime,
                    reservationTime: results.reservationTime ? moment(results.reservationTime) : results.reservationTime,
                    repairTime: results.repairTime ? moment(results.repairTime) : null,
                    customerVehicleCode: results.customerVehicleCode,
                    reviewerTime: results.reviewerTime ? timeShift(results.reviewerTime) : '',
                    billName: results.billName ? results.billName : getUserInfo() ? getUserInfo().nickname : '未获取到', //获取系统业务员
                    billId: results.billId ? results.billId : getUserInfo() ? getUserInfo().id : '', //获取系统业务员ID
                    preferentialAmount: results.orderPreferentialAmount, //优惠金额， 字段写错。 在这改正下。
                })
                setCustomerInfo({
                    selectValue: {
                        customerName: results.customerName,
                        customerId: results.customerId,
                    },
                    data: [
                        {
                            text: results.customerName,
                            value: results.customerId,
                        },
                    ],
                    fetching: false,
                }) //客户列表回显
                setVehicleInfo({
                    data: [
                        {
                            text: results.customerVehicleCode,
                            value: results.customerVehicleId,
                        },
                    ],
                    fetching: false,
                }) //车辆列表回显
                setUserInfo({
                    data: [
                        {
                            text: results.customerUserName,
                            value: results.customerUserId,
                            iphone: results.repairContactPhone,
                        },
                    ],
                    fetching: false,
                }) //用户列表回显
                setTeamInfo({
                    id: results.customerId,
                    data: [{ text: results.teamName, value: results.teamId }],
                    fetching: false,
                }) //团队列表回显
                setSelectVehicle({
                    key: results.customerVehicleId,
                    value: results.customerVehicleId,
                    children: results.customerVehicleCode,
                }) //记录车辆信息
                setOrderStatusType(results.orderStatus) //记录工单状态
                setOrderCode(results.orderCode) //记录工单号
                setOrderCustomerName(results.customerName) //记录客户名称
                setOrderVehicleCode(results.customerVehicleCode) //记录工单状态
                // } else {
                //     message.error(res.retMsg)
                // }
                //所属实体回显
                // {
                //     const { ciBusinessEntityId: value, ciBusinessEntityName: text } = results
                //     value &&
                //         text &&
                //         setEntityData({
                //             data: [
                //                 {
                //                     text,
                //                     value,
                //                 },
                //             ],
                //             fetching: false,
                //         }) //所属实体回显
                // }
            })
        } else {
            // orderForm.setFieldsValue({
            //     staffPhone: getUserIdInfo()?.phone || "",
            // })
            //新增带出字典数据
            orderForm.setFieldsValue({
                billName: getUserInfo() ? getUserInfo().nickname : '未获取到', //获取系统业务员
                billId: getUserInfo() ? getUserInfo().id : '', //获取系统业务员ID
                orderSource: '008002', //工单来源
                // orderType: "014003", //工单类型
                // serviceMethod: "012002", //服务方式
                // serviceType: "011001", //服务类型
                customerType: '017001', //一般客户
            })
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
    }

    const getBussinessEntity = async () => {
        setEntityData({
            data: getBentityListOption(),
            fetching: false,
        })

        // const { shopCode } = getUserOrgan()
        // if(shopCode === 'SHOP-M-00000013' || shopCode === 'SHOP-M-00000020'){
        //     setEntityData({
        //         data: [{
        //             text: '天津欧豪富港汽车贸易有限公司',
        //             value: 'bentity.tj-ouhao',
        //         }],
        //         fetching: false,
        //     })
        //     return
        // }else if(shopCode === 'SHOP-M-00000037' || shopCode === 'SHOP-M-00000062'){
        //     setEntityData({
        //         data: [{
        //             text: '云豪车服（北京）科技服务有限公司',
        //             value: 'bentity.bj-yh-auto-service',
        //         }],
        //         fetching: false,
        //     })
        //     return
        // }
        // //只能选择云豪科技
        // let str = '云豪车服（北京）科技服务有限公司'
        // let data = {
        //     searchItem: {
        //         entityName: str,
        //     },
        //     pageNum: 1,
        //     pageSize: 10,
        //     orders: [
        //         {
        //             column: 'createTime',
        //             rule: 'DESC',
        //         },
        //     ],
        // }
        // getBussinessEntityList(data).then((res) => {
        //     const results = res.retData.list
        //     const dataMap = results.map((team) => ({
        //         text: team.entityName,
        //         value: team.id,
        //     }))
        //     setEntityData({
        //         data: dataMap,
        //         fetching: false,
        //     })

        //     // if(!orderForm.getFieldValue("ciBusinessEntityId")){
        //     //     orderForm.setFieldsValue({
        //     //         ciBusinessEntityId: dataMap.find((item) => item.text === '云豪车服（北京）科技服务有限公司')?.value,
        //     //         ciBusinessEntityName: '云豪车服（北京）科技服务有限公司',
        //     //     })
        //     // }
        // })
    }
    //页面刷新
    const orderDetailsLoad = () => {
        maintenanceRef.current.upData(rowInfo.id)
        materialRef.current.upData(rowInfo.id)
        otherProjectRef.current.upData(rowInfo.id)
        isMode()
        message.success('已刷新')
    }
    //返回按钮
    const handleBack = () => {
        dispatch({
            type: 'removeTabs',
            targetKey: modeKey,
            activeMenu: parentModeKey,
        })
    }
    //地图保存
    const saveAddressDetail = (results) => {
        orderForm.setFieldsValue({
            repairAddress: results.addr,
        })
        setSaveMapAddr(results)
        setMapVisible(false)
    }
    //客户列表查询
    const customerQuery = (value) => {
        setCustomerInfo({
            ...customerInfo,
            // data: [],
            fetching: true,
        })
        getCustomerListNew({
            pageSize: 15,
            currentPage: 1,
            searchItem: {
                queryTemporaryCustomer: 1,
                customerName: value,
            },
        }).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.reduce((arr, item) => {
                    if (item.customerStatus === 0) {
                        arr.push({
                            text: item.customerName,
                            value: item.id,
                            address: item.customerAddress,
                        })
                    }
                    return arr
                }, [])
                setCustomerInfo({
                    ...customerInfo,
                    data: data,
                    fetching: false,
                })
            } else {
                message.warning('系统目前没有客户，请通过左侧添加')
            }
            // }
        })
        // .catch((err) => {
        //     if (err && err.retCode === 500) {
        //         message.warning(err.retMsg)
        //     }
        // })
    }
    //防抖
    const customerQuerys = debounce(customerQuery, 1000)
    const [selectVehicle, setSelectVehicle] = useState(null) //用户选择的车辆信息
    //客户选中, 保存客户信息，车辆和用户使用
    const handleChangeCustomer = (value, option) => {
        console.log(option)
        setOrderCustomerName(option.children)
        setOrderVehicleCode('--')
        setCustomerInfo({
            ...customerInfo,
            selectValue: {
                customerId: option.key,
                customerName: option.children,
            },
        })

        //当客户重新选择时  置空车辆和用户
        orderForm.resetFields(['customerUserId', 'customerVehicleId'])
        setSelectVehicle(null)
    }

    //车辆列表查询
    const vehicleQuery = (value) => {
        setVehicleInfo({
            data: [],
            fetching: true,
        })
        let D = {
            pageSize: 15,
            pageNum: 1,
            searchItem: {
                vehicleCode: value,
                customerId: customerInfo.selectValue.customerId || null,
            },
        }
        getVehicleList(D).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.map((item) => ({
                    text: item.vehicleCode,
                    value: item.id,
                }))
                setVehicleInfo({
                    data: data,
                    fetching: false,
                })
            } else {
                message.warning('该客户下没有车辆，可通过点击左侧名称增加')
            }
            // }
        })
        // .catch((err) => {
        //     if (err) {
        //         message.warning("该客户下没有车辆，可通过点击左侧名称增加")
        //     }
        // })
    }
    //防抖
    const vehicleQuerys = debounce(vehicleQuery, 600)

    //车辆选中, 回显
    const handleChangevehicle = (value, option) => {
        // console.log(value, option);
        setOrderVehicleCode(option.children)
        setSelectVehicle(option)
        value &&
            getVehicleOne(value).then((res) => {
                // if (res && res.retCode === 200) {
                let data = { ...res.retData.basicInfo }
                setCustomerInfo({
                    selectValue: {
                        customerName: data.customerName,
                        customerId: data.customerId,
                    },
                    data: [{ text: data.customerName, value: data.customerId }],
                    fetching: false,
                }) //客户列表回显
                orderForm.setFieldsValue({
                    customerId: data.customerId,
                    customerVehicleCode: data.vehicleCode, //车辆牌照
                })
                // }
            })
    }
    //用户查询
    const userQuery = (value) => {
        setUserInfo({
            data: [],
            fetching: true,
        })
        let D = {
            pageSize: 15,
            currentPage: 1,
            searchItem: {
                customerId: customerInfo.selectValue.customerId || null,
                userName: value,
            },
        }
        newGetList(D).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.map((item) => ({
                    iphone: item.userMobile,
                    // text: `${item.realName}(${item.userMobile})`,
                    text: `${item.realName}`,
                    value: item.id,
                }))
                setUserInfo({
                    data: data,
                    fetching: false,
                })
            } else {
                message.warning('该客户下没有用户，可通过点击左侧名称增加')
            }
            // }
        })
        // .catch((err) => {
        //     if (err) {
        //         message.warning("该客户下没有用户，可通过点击左侧名称增加")
        //     }
        // })
    }
    //防抖
    const userQuerys = debounce(userQuery, 1000)
    //用户列表选择, 回显报修用户电话， 报修用户名字
    const handleChangeUser = (value, option) => {
        if (option) {
            orderForm.setFieldsValue({
                repairContactPhone: option.iphone,
                repairContactName: option.name,
            })
        }
    }

    //团队查询
    // const teamQuery = value => {
    //     setTeamInfo({
    //         data: [],
    //         fetching: true
    //     })
    //     let data = {
    //         searchItem: { teamName: value ? value : null, },
    //         pageSize: 15,
    //         pageNum: 1
    //     };
    //     getTeamList(data).then(res => {
    //         if (res && res.retCode === 200) {
    //             let results = res.retData.list;
    //             const datas = results.map(team => ({
    //                 text: team.teamName,
    //                 value: team.id,
    //             }));
    //             setTeamInfo({
    //                 data: datas,
    //                 fetching: false
    //             })
    //         }
    //     })
    // };
    //防抖
    // const teamQuerys = debounce(teamQuery, 1000);
    //新增，编辑
    const orderSave = async () => {
        if (saveLoading) {
            return
        }
        if (apiCount.current) {
            message.error('接口请求未完成，请稍后再试')
            return
        }
        setSaveLoading(true)
        let maintainMaterialsMap, maintenanceMap, otherItemsMap, orderZfInfo
        const {autoPickMaterial} = rowInfo
        try {
            //采埃孚数据
            orderZfInfo = await caiAiFuRef.current.getSaveData()
            if (!orderZfInfo && orderZfInfo !== null) {
                console.log(orderZfInfo)
                console.log(111)
                message.error('采埃孚信息验证不通过')
                throw '采埃孚信息验证不通过'
            }
            let materialData = await materialRef.current.upMaterialData()
            maintainMaterialsMap = await materialData.map((item, index) => {
                //是三包的话不校验单价数量 是否为0
                if (!orderTypeIsThree) {
                    if (item.serviceNature !== '016001' && !item.unitPrice) {
                        message.error(`维修用料（${item.materialName}）：的单价不能为空`)
                        throw new Error(`${item.materialName}：的单价不能为空`)
                    }
                    if (item.serviceNature !== '016001' && !item.materialNumber) {
                        // console.log(item)
                        message.error(`维修用料（${item.materialName}）：的数量不能为空`)
                        throw new Error(`${item.materialName}：的数量不能为空`)
                    }
                    // if (item.serviceNature === "016001" && !item.claimNumber) {
                    //     // console.log(item)
                    //     message.error(`维修用料（${item.materialName}）：的索赔单号不能为空`)
                    //     throw new Error(`${item.materialName}：的索赔单号不能为空`)
                    // }
                }
                //去分是否自动领料
                let idData = {}
                if(autoPickMaterial === 0){
                    idData.maintainOrderId = item.maintainOrderId
                    idData.maintainOrderMaterialId = item.maintainOrderMaterialId
                }else{
                    idData.purchaseDetailId = item.purchaseDetailId
                }
                return {
                    ...idData,
                    id: item.id || null,
                    maintainOrderId: item.maintainOrderId,
                    maintainOrderMaterialId: item.maintainOrderMaterialId,
                    // threeGuaranteesUnit: item.threeGuaranteesUnit, //三包状态
                    // claimNumber: item.claimNumber,
                    serviceNature: item.serviceNature, //服务性质
                    preferentialAmount: item.preferentialAmount, //优惠
                    partUnit: item.partUnit, //单位
                    materialDiscount: item.materialDiscount ? item.materialDiscount : null, //折扣
                    unitPrice: item.unitPrice,
                    materialNumber: item.materialNumber, //物料数量
                    giftFlag: item.giftFlag, //是否赠送
                    maintainOrderCode: item.maintainOrderCode,
                    serialNumber: index + 1,
                    materialName: item.materialName,
                    remark: item.remark,
                }
            })
            //项目数据
            let maintenanceData = await maintenanceRef.current.upMaintenancelData()
            maintenanceMap = await maintenanceData.map((item) => {
                //是三包的话不校验单价数量 是否为0
                if (!orderTypeIsThree) {
                    if (item.serviceNature !== '016001' && !item.workHour) {
                        message.error(`维修项目（${item.itemName}）：的工时不能为空`)
                        throw new Error(`维修项目（${item.itemName}）：的工时不能为空`)
                    }
                    if (item.serviceNature !== '016001' && !item.hourPrice) {
                        message.error(`${item.itemName}：的单价不能为空`)
                        throw new Error(`${item.itemName}：的单价不能为空`)
                    }
                }
                return {
                    id: item.id || null, //编辑带过来的id
                    itemId: item.itemId, //维修项目id
                    preferentialAmount: item.preferentialAmount, //维修项优惠
                    itemName: item.itemName, //项目名称
                    itemCode: item.itemCode, //项目编码
                    itemWorkHours: item.workHour, //项目工时
                    workHoursPrice: item.hourPrice, //项目单价
                    itemDiscount: item.discount ? item.discount : null, //折扣
                    serviceNature: item.serviceNature, //服务性质
                    remark: item.remark, //备注
                    // threeGuaranteesUnit: item.threeGuaranteesUnit, //三包状态
                    // claimNumber: item.claimNumber, //索赔单号
                    giftFlag: item.giftFlag, //是否赠送
                    prepayFlag: item.prepayFlag,
                }
            })

            let otherItems = await otherProjectRef.current.upOtherData()
            otherItemsMap = await otherItems.map((item) => {
                if (!item.itemName) {
                    message.error(`项目名称不能为空`)
                    throw new Error(`项目名称不能为空`)
                } else if (!item.itemCode) {
                    message.error(`项目编号不能为空`)
                    throw new Error(`项目编号不能为空`)
                } else if (!item.unitPrice) {
                    message.error(`单价不能为空`)
                    throw new Error(`单价不能为空`)
                } else if (!item.itemNumber) {
                    message.error(`数量不能为空`)
                    throw new Error(`数量不能为空`)
                }

                return item
            })
        } catch (error) {
            console.log(error)
            setSaveLoading(false)
            return
        }
        orderForm
            .validateFields()
            .then(async (values) => {
                //判断客户是否完善资料,没有完善资料需要先完善资料
                let { retData } = await getCustomerOneNew(values.customerId)
                if (!retData.customerInfoCompleteStatus) {
                    Modal.confirm({
                        title: '当前客户为未审核状态，需完善客户信息并审核通过后才可进行下一步操作，是否现在完善客户信息',
                        okText: '是',
                        cancelText: '否',
                        width: '820px',
                        centered: true,
                        onOk: () => {
                            setSaveLoading(false)
                            setOrderEditCustomerVisible(true)
                        },
                        onCancel: () => {},
                    })

                    return
                }

                let data = {
                    ...values,
                    createTime: null,
                    teamEstimatedArrivalTime: null,
                    teamEstimatedFinishTime: null,
                    reservationTime: values.reservationTime ? values.reservationTime.format('YYYY-MM-DDTHH:mm:ss') : null,
                    repairTime: values.repairTime ? values.repairTime.format('YYYY-MM-DDTHH:mm:ss') : null,
                    outFactoryTime: values.outFactoryTime ? values.outFactoryTime.format('YYYY-MM-DDTHH:mm:ss') : null,
                    entryFactoryTime: values.entryFactoryTime ? values.entryFactoryTime.format('YYYY-MM-DDTHH:mm:ss') : null,
                    maintainMaterials: maintainMaterialsMap,
                    // parts: materialMap,
                    items: maintenanceMap,
                    otherItems: otherItemsMap,
                    orderPreferentialAmount: values.preferentialAmount, //优惠金额， 字段写错。 在这改正下。
                    orderZfInfo,
                }
                delete data.weiXiuRenYuan
                delete data.mainWeiXiuRenYuan
                if (!saveMapAddr || !saveMapAddr.latitude || !saveMapAddr.longitude) {
                    message.error('定位获取失败，请重新选择定位')
                    return
                }
                if (mode === 'insert') {
                    orderRepair({
                        ...data,
                        repairLat: saveMapAddr.latitude,
                        repairLng: saveMapAddr.longitude,
                    }).then((res) => {
                        message.success('工单新增成功')
                        dispatch({
                            type: 'removeTabs',
                            targetKey: modeKey,
                            activeMenu: 'pendingOrder',
                        })
                    })
                } else if (mode === 'edit') {
                    // debugger;
                    //审核勾选只传1，  否则删除；
                    // if (data.reviewFlag) {
                    //     data.reviewFlag = 1
                    // } else {
                    //     delete data.reviewFlag
                    // }
                    //重新审核
                    if (data.resubmitReview === undefined) {
                        delete data.resubmitReview
                    }
                    //编辑的时候用户选择地图， 就把已经选择的给他
                    if (saveMapAddr) {
                        data.repairLat = saveMapAddr.latitude
                        data.repairLng = saveMapAddr.longitude
                    }
                    //编辑过来有开单员的就不用在传拉。
                    if (rowInfo.billName && rowInfo.billId) {
                        delete data.billName
                        delete data.billId
                    }
                    editOrder(data).then((res) => {
                        // if (res && res.retCode === 200) {
                        message.success('工单编辑成功')
                        handleBack()
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                    // .catch((err) => {
                    //     if (err && err.retCode === 500) {
                    //         message.error(err.retMsg)
                    //     }
                    // })
                }
            })
            .catch((error) => {
                console.log(error)
            })
        saveTimer = setTimeout(() => setSaveLoading(false), 3000)
    }
    //查看车辆故障图片
    const handleLookImg = (str) => {
        setImgLookData({ visible: true, repairMode: str })
    }

    //控制时间
    // const disabledDate = (current) => {
    //     return current && current < moment().startOf("day")
    // }

    //详情工单取消
    const orderCancelReviewTask = async () => {
        let orderCancelReason = '' //记录工单作废原因
        Modal.confirm({
            title: '请选择取消工单的原因',
            content: (
                <Select
                    showSearch
                    placeholder="请选择取消工单的原因"
                    optionFilterProp="children"
                    onChange={(value) => {
                        orderCancelReason = value
                    }}
                    style={{ width: '200px' }}
                >
                    {getPcodeDict('041').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    ))}
                </Select>
            ),
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    if (orderCancelReason) {
                        resolve(orderCancelReason)
                    } else {
                        message.warning('请输入工单作废的原因')
                        reject('请输入工单作废的原因')
                    }
                }).then((res) => {
                    let data = {
                        submitReason: res,
                        orderId: rowInfo.id,
                    }
                    orderCancelReview(data).then((res) => {
                        // if (res && res.retCode === 200) {
                        setTimeout(() => {
                            handleBack()
                        }, 500)
                        message.success('本条工单已申请作废')
                        // } else {
                        //     message.warning(res.retMsg)
                        // }
                    })
                })
            },
            onCancel: () => {},
        })
    }
    //新增 客户
    const handleInsertCrms = (str) => {
        // //编辑页面  用户不可lable不可操作
        if (mode === 'edit' && str === 'userRecord') {
            return
        }
        //判断点击是车辆牌照并且 车辆是选中状态就进入编辑页面，否则是新增页面
        let name = {
            userRecord: '用户新增',
            clientRecord: '客户新增',
            vehicleRecord: '车辆新增',
        }
        let isCarEdit = false
        let editModeKey = `${str}/insert`
        if (str === 'vehicleRecord' && mode !== 'insert' && selectVehicle && selectVehicle.key) {
            isCarEdit = true
            editModeKey = `${str}/edit/${selectVehicle.key}`
        }
        let obj = {
            userRecord: (
                <UserDetails
                    modeKey={editModeKey}
                    dispatch={dispatch}
                    mode="insert"
                    rowInfo={{ orderEditModeKey: modeKey }}
                    customerOrder={customerInfo.selectValue}
                />
            ),
            clientRecord: <ClientDetails modeKey={editModeKey} dispatch={dispatch} mode="insert" rowInfo={{ orderEditModeKey: modeKey }} />,
            vehicleRecord: isCarEdit ? (
                <VehicleDetails
                    modeKey={editModeKey}
                    dispatch={dispatch}
                    mode="edit"
                    rowInfo={{
                        id: selectVehicle.key,
                        orderEditModeKey: modeKey,
                        orderId: rowInfo.id,
                    }}
                /> //传过去车辆id，传过去当前工单详情的编辑id，保存完回来
            ) : (
                <VehicleDetails
                    modeKey={editModeKey}
                    dispatch={dispatch}
                    mode="insert"
                    rowInfo={{ orderEditModeKey: modeKey }}
                    customerOrder={customerInfo.selectValue}
                />
            ),
        }
        dispatch({
            type: 'changeTabs',
            name: isCarEdit ? '车辆编辑' : name[str],
            activeMenu: editModeKey,
            content: obj[str],
        })
    }

    //订单驳回
    // const handleOrderReject = () => {
    //     let str = "" //记录工单驳回原因
    //     Modal.confirm({
    //         title: "请输入驳回工单的原因",
    //         content: (
    //             <Input
    //                 placeholder="请输入工单驳回的原因"
    //                 onChange={(e) => {
    //                     str = e.target.value
    //                 }}
    //             />
    //         ),
    //         okText: "确定",
    //         cancelText: "取消",
    //         onOk: () => {
    //             return new Promise((resolve, reject) => {
    //                 if (str) {
    //                     resolve(str)
    //                 } else {
    //                     message.warning("请输入工单驳回的原因")
    //                     reject("请输入工单驳回的原因")
    //                 }
    //             }).then((res) => {
    //                 orderReject({ orderId: rowInfo.id, rejectInfo: res }).then((res) => {
    //                     // if (res && res.retCode === 200) {
    //                     handleBack()
    //                     message.success("驳回成功")
    //                     // } else {
    //                     //     message.warning(res.retMsg)
    //                     // }
    //                 })
    //                 // .catch((err) => message.error(err.retMsg))
    //             })
    //         },
    //         onCancel: () => {},
    //     })
    // }

    //展示修改报修用户名字
    const handleModifycustomerUser = () => {
        if (orderForm.getFieldValue('repairContactName')) {
            setModifyContactName(true)
        } else {
            message.error('没有要修改的报修用户')
        }
    }

    const modifcustomerUserName = (name, id) => {
        console.log(orderForm.getFieldsValue())
        console.log(orderForm.getFieldValue('repairContactPhone'))
        const data = {
            data: [
                {
                    iphone: orderForm.getFieldValue('repairContactPhone'),
                    text: name,
                    value: id,
                },
            ],
            fetching: false,
        }
        setUserInfo(data)
        orderForm.setFieldsValue({
            repairContactName: name,
            customerUserId: id,
        })
    }
    //选中维修历史
    const choosedRepairHistoryOk = (data) => {
        maintenanceRef.current.setItemListHistory(data.orderItems)
        materialRef.current.setPartListForHistory(data.orderParts)
    }

    //选中维修模版
    const choosedRepairTemplateOk = (data) => {
        maintenanceRef.current.setItemListTemplate(data.items)
        materialRef.current.setPartListForTemplate(data.parts)
    }
    //生成维修模版弹窗
    const handleCreateRepairTeplete = async () => {
        let materialMap, maintenanceMap
        try {
            let materialData = await materialRef.current.upMaterialData()
            let partAmount = 0
            materialMap = await materialData.map((item) => {
                partAmount += Number(item.totalCount)
                return {
                    id: null,
                    partId: item.id, //物资id
                    partCode: item.partCode, //配件编号
                    partName: item.partName, //配件名称
                    partAttr: item.partAttr || '', //配件属性
                    partUnit: item.partBrand || '', //品牌
                    partSpec: item.partSpec || '', //规格
                    partApplicableModel: item.partApplicableModel || '', //使用车型
                    partRetailPrice: item.unitPrice, //零售价
                    partNumber: item.partNumber, //数量
                    partAmount: item.totalCount, //总计
                    remark: '',
                }
            })
            //项目数据
            let maintenanceData = await maintenanceRef.current.upMaintenancelData()
            let itemAmount = 0
            maintenanceMap = await maintenanceData.map((item) => {
                itemAmount += Number(item.totalAll)
                return {
                    id: null, //编辑带过来的id
                    itemId: item.id, //维修项目id
                    itemCode: item.itemCode, //项目编码
                    itemName: item.itemName, //项目名称
                    itemAttr: item.itemAttr || '', //项目属性
                    itemWorkHours: item.workHour, //工时
                    workHoursPrice: item.hourPrice, //单价
                    itemAmount: item.totalAll, //工时费
                    remark: '',
                }
            })
            let rowData = {
                items: maintenanceMap,
                itemAmount,
                parts: materialMap,
                partAmount,
            }
            setRepairTemplateData(rowData)
            setShowRepairTemplateDetail(true)
        } catch (error) {
            message.error('数据切换错误')
        }
    }
    //查看模式
    const isLook = (str) => {
        return str === 'look' ? true : false
    }
    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    //选择实体
    const handleChangeEntity = (value, option) => {
        orderForm.setFieldsValue({
            ciBusinessEntityName: value ? option.children : undefined,
        })
    }

    const preferentialAmountInputKeyUp = (e) => {
        if (e.keyCode === 8) {
            //删除键
            orderForm.setFieldsValue({
                preferentialAmount: '0',
            })
        }
    }

    const orderReturn = (str) => {
        const obj = {
            req: {
                text: '发起审批',
                api: orderReturnReq,
            },
            approved: {
                text: '通过审批',
                api: orderReturnApproved,
            },
        }
        const data = obj[str]
        const model = Modal.confirm({
            title: '提示',
            content: `确定${data.text}?`,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                data.api(rowInfo.id).then((res) => {
                    message.success('操作成功')
                    isMode()
                })
            },
            onCancel: () => {},
        })
    }

    return (
        <>
            <Provider
                value={{
                    orderDetail: orderInfoOne,
                    mode,
                }}
            >
                <div className="order-box container-fixed ">
                    {/* <div className="toolbar"><span className="modules-name">工单管理</span></div> */}
                    <div className="list-container" style={{}}>
                        <div className="list-header">
                            <div className="list-name">
                                <span style={{ marginRight: '20px' }}>工单详情</span>
                            </div>
                            <div className="list-but">
                                {!isLook(mode) && (
                                    <Button
                                        disabled={orderStatusDisabled || isLoading}
                                        loading={saveLoading}
                                        shape="round"
                                        className="but-left"
                                        onClick={orderSave}
                                    >
                                        保存
                                    </Button>
                                )}
                                <Button shape="round" className="but-left" onClick={handleBack}>
                                    返回
                                </Button>
                                {isCancelReviewStatus === 'wait' && (
                                    <Button
                                        shape="round"
                                        className="but-left"
                                        onClick={() => {
                                            setReviewCancelVisible(true)
                                        }}
                                    >
                                        作废审批
                                    </Button>
                                )}
                                {mode !== 'insert' && (!isCancelReviewStatus || isCancelReviewStatus === 'reject') && (
                                    <>
                                        {orderInfoOne.orderStatus !== 'completed' && (
                                            <>
                                                <Button
                                                    shape="round"
                                                    className="but-left"
                                                    disabled={mode === 'look' ? true : false}
                                                    onClick={() => setShowOrderDispatchVisible('paidan')}
                                                >
                                                    派单
                                                </Button>
                                                {/* <Button
                                                    shape="round"
                                                    className="but-left"
                                                    disabled={mode === 'look' ? true : false}
                                                    onClick={orderCancelReviewTask}
                                                >
                                                    作废
                                                </Button> */}
                                            </>
                                        )}

                                        <Button
                                            shape="round"
                                            className="but-left"
                                            onClick={() =>
                                                setPrintVisible({
                                                    visible: true,
                                                    orderId: rowInfo.id,
                                                })
                                            }
                                        >
                                            打印
                                        </Button>
                                        <Button shape="round" className="but-left" onClick={orderDetailsLoad}>
                                            刷新
                                        </Button>
                                        {orderInfoOne.orderStatus === 'pending_system_review' && (
                                            <Button
                                                shape="round"
                                                className="but-left"
                                                onClick={() => {
                                                    setReviewVisible(true)
                                                }}
                                            >
                                                审核
                                            </Button>
                                        )}

                                        {orderInfoOne.orderStatus === 'completed' && !orderInfoOne.orderReturnStatus && (
                                            <Button
                                                shape="round"
                                                className="but-left"
                                                onClick={() => {
                                                    orderReturn('req')
                                                }}
                                            >
                                                退单审批
                                            </Button>
                                        )}

                                        {orderInfoOne.orderStatus === 'completed' && orderInfoOne.orderReturnStatus === 'pending_review' && (
                                            <Button
                                                shape="round"
                                                className="but-left"
                                                onClick={() => {
                                                    orderReturn('approved')
                                                }}
                                            >
                                                审批通过
                                            </Button>
                                        )}

                                        {/* <Button shape="round" className="but-left" onClick={handleOrderReject} disabled={mode === "look" ? true : false}>
                                        驳回
                                    </Button> */}
                                        {/* <Button
                                        shape="round"
                                        className="but-left"
                                        onClick={() => {
                                            setShowSanbe(true)
                                        }}
                                    >
                                        三包信息
                                    </Button> */}
                                        {/* <Button shape="round" className="but-left" onClick={() => {
                                    console.log(rowInfo.customerId)
                                    if (rowInfo.customerId) {
                                        message.warning("该工单下没有客户信息");
                                        return;
                                    }
                                    setOrderPayInfo({ visible: true, orderId: rowInfo.id, customerId: rowInfo.customerId });
                                }} disabled={mode === "look" ? true : false}>结算</Button> */}
                                        <Button shape="round" className="but-right" onClick={() => handleLookImg('customer')}>
                                            故障照片
                                        </Button>
                                        <Button shape="round" className="but-right" onClick={() => handleLookImg('team')}>
                                            现场照片
                                        </Button>
                                        <Button
                                            shape="round"
                                            className="but-right"
                                            onClick={() =>
                                                setLookVisible({
                                                    visible: true,
                                                    taskMode: 'comment',
                                                })
                                            }
                                        >
                                            客户评价
                                        </Button>
                                        <Button
                                            shape="round"
                                            className="but-right"
                                            onClick={() =>
                                                setLookVisible({
                                                    visible: true,
                                                    taskMode: 'payment',
                                                })
                                            }
                                        >
                                            支付信息
                                        </Button>
                                        {mode === 'look' ? (
                                            <Button
                                                shape="round"
                                                className="but-right"
                                                onClick={() => {
                                                    getSettleInfo(rowInfo.id, 'look')
                                                    setSettleVisible(true)
                                                }}
                                            >
                                                结算查看
                                            </Button>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}
                                {!isCancelReviewStatus && (
                                    <Button shape="round" className="but-left" onClick={handleCreateRepairTeplete}>
                                        生成维修模版
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Form form={orderForm} {...formLayout}>
                    <div className="list-container" style={{ marginTop: '90px' }}>
                        <div className="form-details-warp" style={{ padding: '20px 20px 0 0', position: 'relative' }}>
                            {/* {mode === "edit" && orderStateSystem && (
                                <div className="reviewFlag">
                                    <Form.Item name="reviewFlag" valuePropName="checked" cla>
                                        <Checkbox />
                                    </Form.Item>
                                    <span>审核</span>
                                </div>
                            )} */}
                            {mode === 'edit' && orderStateSystem && (
                                <div className="reviewFlag" style={{ width: '300px' }}>
                                    <Form.Item name="resubmitReview" label="重新审核" valuePropName="checked" size="large">
                                        <Checkbox />
                                    </Form.Item>
                                </div>
                            )}
                            <Tabs
                                defaultActiveKey="1"
                                activeKey={actTabs}
                                onChange={(e) => {
                                    setActTabs(e)
                                }}
                            >
                                <TabPane tab="工单信息" key="1">
                                    <Row>
                                        <div style={{ marginBottom: '10px', marginLeft: '20px', color: '#333' }}>
                                            {mode !== 'insert' && orderCode && (
                                                <span style={{ fontSize: '14px', marginRight: '20px' }}>{`工单编号:${orderCode}`}</span>
                                            )}
                                            {mode !== 'insert' && orderCustomerName && (
                                                <span style={{ fontSize: '14px', marginRight: '20px' }}>{`客户名称:${orderCustomerName}`}</span>
                                            )}
                                            {mode !== 'insert' && orderInfoOne.customerType && (
                                                <span style={{ fontSize: '14px', marginRight: '20px' }}>{`客户类型:${
                                                    getPcodeDict('017').children.filter((item) => item.dictCode === orderInfoOne.customerType)[0]?.title || '--'
                                                }`}</span>
                                            )}
                                            {mode !== 'insert' && orderInfoOne.orderSource && (
                                                <span style={{ fontSize: '14px', marginRight: '20px' }}>{`工单来源:${
                                                    getPcodeDict('008').children.filter((item) => item.dictCode === orderInfoOne.orderSource)[0]?.title
                                                }`}</span>
                                            )}
                                            {mode !== 'insert' && orderVehicleCode && (
                                                <span style={{ fontSize: '14px', marginRight: '20px' }}>{`车辆牌照:${orderVehicleCode}`}</span>
                                            )}
                                            {mode !== 'insert' && orderStatusType && orderStatus[orderStatusType] && (
                                                <span style={{ fontSize: '14px', marginRight: '20px' }}>{`状态:${orderStatus[orderStatusType]}`}</span>
                                            )}
                                            {mode !== 'insert' && orderInfoOne.orderReturnStatus && (
                                                <span style={{ fontSize: '14px' }}>{`退单状态:${OrderReturnStatus[orderInfoOne.orderReturnStatus]}`}</span>
                                            )}
                                        </div>
                                    </Row>
                                    <Row>
                                        {/* <Col span={6} xxl={4}>
                                            <Form.Item label="工单编号" name="orderCode">
                                                <Input placeholder="自动生成" disabled />
                                            </Form.Item>
                                        </Col> */}
                                        <Col span={7}>
                                            {/* <Form.Item
                                        label={<span style={{ color: "#00f", cursor: 'pointer' }} onClick={() => mode === "edit" ? setOrderEditCustomerVisible(true) : handleInsertCrms("clientRecord")}>客户名称</span>}
                                        name="customerId"
                                        rules={[{ required: true, message: "请选择客户" }]}
                                    > */}
                                            <Form.Item
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                label={
                                                    <span
                                                        style={{
                                                            color: '#00f',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => (mode === 'edit' ? setOrderEditCustomerVisible(true) : setOrderAddCustomerVisible(true))}
                                                    >
                                                        客户名称
                                                    </span>
                                                }
                                                name="customerId"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择客户',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    // disabled={isLook(mode) || mode === 'edit' ? true : false}
                                                    disabled={isLook(mode) || (mode === 'edit' && orderInfoOne.orderStatus !== 'pending_order') ? true : false}
                                                    showSearch
                                                    placeholder="请选择客户"
                                                    value={customerInfo.selectValue.customerName}
                                                    notFoundContent={customerInfo.fetching ? <Spin size="small" /> : null}
                                                    filterOption={false}
                                                    onFocus={() => customerQuery('')}
                                                    onChange={handleChangeCustomer}
                                                    onSearch={customerQuerys}
                                                    style={{ width: '100%' }}
                                                >
                                                    {customerInfo.data.map((item) => (
                                                        <Option value={item.value} key={item.value} address={item.address}>
                                                            {item.text}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            {/* <Form.Item
                                        label={<span style={{ color: "#00f", cursor: 'pointer' }} onClick={() => handleInsertCrms("vehicleRecord")}>车辆牌照</span>}
                                        name="customerVehicleId"
                                        rules={[{ required: true, message: "请选择车辆" }]}
                                    > */}
                                            <Form.Item
                                                label={
                                                    <span
                                                        style={{
                                                            color: '#00f',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            // mode !== "edit" ? setAddVehicleRecordVisible(true) : handleInsertCrms("vehicleRecord")
                                                            // if(selectVehicle && selectVehicle.value){
                                                            setAddVehicleRecordVisible(true)
                                                            // }
                                                        }}
                                                    >
                                                        车辆牌照
                                                    </span>
                                                }
                                                name="customerVehicleId"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择车辆',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled={isLook(mode)}
                                                    showSearch
                                                    // allowClear
                                                    placeholder="请选择车辆"
                                                    notFoundContent={vehicleInfo.fetching ? <Spin size="small" /> : null}
                                                    filterOption={false}
                                                    onFocus={() => vehicleQuery('')}
                                                    onSearch={vehicleQuerys}
                                                    onSelect={handleChangevehicle}
                                                    style={{ width: '100%' }}
                                                >
                                                    {vehicleInfo.data.map((item) => (
                                                        <Option value={item.value} key={item.value}>
                                                            {item.text}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                label={
                                                    <span
                                                        style={{
                                                            color: '#00f',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={handleModifycustomerUser}
                                                    >
                                                        报修用户
                                                    </span>
                                                }
                                                name="customerUserId"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择报修用户',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    // disabled={mode === 'insert' ? false : true}
                                                    disabled={isLook(mode) || (mode === 'edit' && orderInfoOne.orderStatus !== 'pending_order') ? true : false}
                                                    showSearch
                                                    // allowClear
                                                    placeholder="请选择报修用户"
                                                    notFoundContent={userInfo.fetching ? <Spin size="small" /> : null}
                                                    filterOption={false}
                                                    onFocus={() => userQuery('')}
                                                    onSearch={userQuerys}
                                                    onChange={handleChangeUser}
                                                    style={{ width: '100%' }}
                                                >
                                                    {userInfo.data.map((item) => (
                                                        <Option value={item.value} key={item.value} iphone={item.iphone} name={item.text}>
                                                            {`${item.text}(${item.iphone})`}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                label="服务方式"
                                                name="serviceMethod"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择服务方式',
                                                    },
                                                ]}
                                            >
                                                <Select disabled={isLook(mode)} allowClear placeholder="请选择服务方式" style={{ width: '100%' }}>
                                                    {getPcodeDict('012').children.map((item) => (
                                                        <Option value={item.dictCode} key={item.id}>
                                                            {item.title}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item
                                                label="故障类型"
                                                name="serviceType"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择故障类型',
                                                    },
                                                ]}
                                            >
                                                <Select disabled={isLook(mode)} allowClear placeholder="请选择故障类型" style={{ width: '100%' }}>
                                                    {getPcodeDict('011').children.map((item) => (
                                                        <Option value={item.dictCode} key={item.id}>
                                                            {item.title}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                            <Form.Item
                                                labelCol={{ span: 4 }}
                                                wrapperCol={{ span: 20 }}
                                                label="报修地址"
                                                name="repairAddress"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请通过地图来获取地址',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="通过地图实时位置获取地址"
                                                    disabled
                                                    addonAfter={
                                                        <Button className="map-but" onClick={() => setMapVisible(true)}>
                                                            获取位置
                                                        </Button>
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item
                                                name="customerVehicleMileage"
                                                label="行驶里程数"
                                                rules={[
                                                    {
                                                        required: orderStateSystem,
                                                        message: '请输入行驶里程数',
                                                    },
                                                    {
                                                        pattern: /^\d+(\.\d{0,2})?$/,
                                                        message: '请输入数字且可保留两位小数',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="请输入里程数" suffix="KM" disabled={isLook(mode)} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={5}>
                                            <Form.Item
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                label="救援里程数"
                                                name="mileage"
                                                rules={[
                                                    {
                                                        pattern: /^\d+(\.\d{0,2})?$/,
                                                        message: '请输入数字且可保留两位小数',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="请输入里程数" suffix="KM" disabled={isLook(mode)} />
                                            </Form.Item>
                                        </Col>

                                        {/* <Col span={4}>
                                            <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} label="故障内容" name="faultClassification">
                                                <Select disabled={isLook(mode)} placeholder="请选择故障内容" style={{ width: '100%' }}>
                                                    {getPcodeDict('037').children.map((item) => (
                                                        <Option value={item.dictCode} key={item.id}>
                                                            {item.title}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col> */}

                                        <Col span={4}>
                                            <Tooltip
                                                placement="topLeft"
                                                title="温馨提醒：当选中三包时，维修项目和维修物料的单价,工时，数量，金额都将置0，这可能导致您在次选择工单类型(收费或三包/收费)时,所有的价格，数量会恢复到初始值。"
                                                arrowPointAtCenter
                                            >
                                                <Form.Item
                                                    label="工单类型"
                                                    name="orderType"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '请选择工单类型',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        disabled={isLook(mode)}
                                                        placeholder="请选择工单类型"
                                                        style={{ width: '100%' }}
                                                        onChange={handleOrderTypeChange}
                                                    >
                                                        {getPcodeDict('014').children.map((item) => (
                                                            <Option value={item.dictCode} key={item.id}>
                                                                {item.title}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Tooltip>
                                        </Col>

                                        <Col span={16}>
                                            <Form.Item
                                                labelCol={{ span: 2 }}
                                                wrapperCol={{ span: 22 }}
                                                label="故障描述"
                                                name="faultDesc"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入故障描述',
                                                    },
                                                ]}
                                            >
                                                <Input.TextArea allowClear autoSize={true} placeholder="请输入故障描述" disabled={isLook(mode)} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item label="送修人" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} name="senderRepairUserName">
                                                <Input placeholder="送修人" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item
                                                label="送修电话"
                                                // labelCol={{ span: 6 }}
                                                // wrapperCol={{ span: 18 }}
                                                name="senderRepairUserPhone"
                                                rules={[
                                                    {
                                                        pattern: /^1\d{10}$/,
                                                        message: '请输入正确的手机号',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="送修电话" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <Form.Item label="所属实体" name="ciBusinessEntityId">
                                                <Select
                                                    showSearch
                                                    disabled={isLook(mode)}
                                                    allowClear
                                                    placeholder="请选择所属实体"
                                                    style={{ width: '100%' }}
                                                    notFoundContent={entityData.fetching ? <Spin size="small" /> : null}
                                                    optionFilterProp="children"
                                                    // onFocus={() => fetchEntity()}
                                                    // onSearch={fetchEntitys}
                                                    filterOption={false}
                                                    onChange={handleChangeEntity}
                                                >
                                                    {entityData.data.map((item) => (
                                                        <Select.Option value={item.value} key={item.value}>
                                                            {item.text}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {/* <Col span={3}>
                                            <Form.Item label="工单属性" name="orderAttr">
                                                <Select
                                                    showSearch
                                                    disabled={isLook(mode)}
                                                    allowClear
                                                    placeholder="请选择工单属性"
                                                    style={{ width: '100%' }}
                                                    optionFilterProp="children"
                                                >
                                                    {ItemAttraArr.map((item) => (
                                                        <Select.Option value={item[0]} key={item[0]}>
                                                            {item[1]}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col> */}

                                        <Col span={5}>
                                            <Form.Item
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                label={
                                                    orderForm.getFieldValue('settlementInfo')?.invoiceMd5 ? (
                                                        <span
                                                            style={{
                                                                color: '#00f',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <TextPriviewImage
                                                                text={'发票号码'}
                                                                previewList={orderForm.getFieldValue('settlementInfo')?.invoiceMd5.split(',')}
                                                                imageUrl={
                                                                    'https://api-2.yunhaogroup.com/fsrv/fetch/' +
                                                                    orderForm.getFieldValue('settlementInfo')?.invoiceMd5 +
                                                                    '?Authorization=' +
                                                                    getToken()
                                                                }
                                                            />
                                                        </span>
                                                    ) : (
                                                        '发票号码'
                                                    )
                                                }
                                                //name={orderForm.getFieldValue('settlementInfo')?.invoiceNum}
                                            >
                                                <Input placeholder="自动带出" disabled={'true'} value={orderForm.getFieldValue('settlementInfo')?.invoiceNum} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                label="发票类型"
                                                //name="settlementInfo.invoiceTypeName"
                                            >
                                                <Input
                                                    placeholder="自动带出"
                                                    disabled={'true'}
                                                    value={orderForm.getFieldValue('settlementInfo')?.invoiceTypeName}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                label="开票人"
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                //name="invoiceOperator"
                                            >
                                                <Input
                                                    placeholder="自动带出"
                                                    disabled={'true'}
                                                    value={orderForm.getFieldValue('settlementInfo')?.invoiceOperator}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                label="发票上传时间"
                                                //name="invoiceUploadTime"
                                            >
                                                <Input
                                                    placeholder="自动带出"
                                                    disabled={'true'}
                                                    value={
                                                        orderForm.getFieldValue('settlementInfo')?.invoiceUploadTime
                                                            ? moment(orderForm.getFieldValue('settlementInfo')?.invoiceUploadTime).format('YYYY-MM-DD HH:mm:ss')
                                                            : ''
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        {/* <Col span={6} xxl={4}>
                                            <Form.Item
                                                label="里程单价"
                                                name="mileageUnitPrice"
                                                rules={[
                                                    {
                                                        pattern: /^\d+(\.\d{0,2})?$/,
                                                        message: "请输入数字且可保留两位小数",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="请输入里程单价" prefix="¥" suffix="元" disabled={isLook(mode)} />
                                            </Form.Item>
                                        </Col>
                                        
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="工单来源" name="orderSource">
                                                <Select disabled={isLook(mode)} allowClear placeholder="请选择工单来源" style={{ width: "100%" }}>
                                                    {getPcodeDict("008").children.map((item) => (
                                                        <Option value={item.dictCode} key={item.id}>
                                                            {item.title}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    
                                        
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="对接人" name="dockPeople">
                                                <Input placeholder="请输入对接人" disabled={isLook(mode)} />
                                            </Form.Item>
                                        </Col>

                                      */}

                                        {/* <Col span={6} xxl={4}>
                                            <Form.Item label="工单编号" name="orderCode">
                                                <Input placeholder="自动生成" disabled />
                                            </Form.Item>
                                        </Col> */}

                                        {/* <Col span={6} xxl={4}>
                                        <Form.Item label="报修时间" name="createTime">
                                            <DatePicker
                                                disabled
                                                placeholder="自动生成"
                                                className="pub-select"
                                                format="YYYY-MM-DD HH:mm:ss"
                                                showTime={{
                                                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                                                }}
                                                disabledDate={disabledDate}
                                            />
                                        </Form.Item>
                                    </Col> */}

                                        {/* <Col span={6} xxl={4}>
                                        <Form.Item label="客户类型" name="customerType">
                                            <Select disabled placeholder="请选择客户类型" style={{ width: "100%" }}>
                                                {getPcodeDict("017").children.map((item) => (
                                                    <Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col> */}
                                        {/* <Col span={6} xxl={4}>
                                            <Form.Item label="开单员电话" name="staffPhone">
                                                <Input disabled placeholder="自动获取" />
                                            </Form.Item>
                                        </Col> */}
                                        {/* <Col span={6} xxl={4}>
                                            <Form.Item label="开单员" name="billName">
                                                <Input placeholder="请输入开单员,登录带出" disabled />
                                            </Form.Item>
                                        </Col> */}

                                        {/* <Col span={6} xxl={4}>
                                            <Form.Item label="审单员" name="reviewerName">
                                                <Input placeholder="审单员" disabled />
                                            </Form.Item>
                                        </Col> */}
                                        {/* <Col span={6} xxl={4}>
                                            <Form.Item label="审核时间" name="reviewerTime">
                                                <Input placeholder="审核时间" disabled />
                                            </Form.Item>
                                        </Col> */}
                                    </Row>
                                </TabPane>
                                {/*<TabPane
                                    tab={`${orderInfoOne.customerVehicleCode ? "车辆信息(" + orderInfoOne.customerVehicleCode + ")" : "车辆信息"}`}
                                    key="2"
                                >
                                    <Row>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="车辆品牌" name="customerVehicleBrand">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item label="vin码" name="customerVehicleVin">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="发动机号" name="customerEngineCode">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item label="变速箱号" name="gearboxCode">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item label="车型" name="customerVehicleModel">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="车系" name="customerVehicleSeries">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item label="车桥" name="customerVehicleAxle">
                                                <Input placeholder="可通过车辆选择带出" disabled={mode === "insert" ? false : true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>*/}
                                <TabPane tab={`${orderInfoOne.orderActualAmount ? '结算信息(' + orderInfoOne.orderActualAmount + ')' : '结算信息'}`} key="3" forceRender={true}>
                                    <Row>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="预付款" name="prepaymentsAmount">
                                                <Input disabled placeholder="预付款" prefix="¥" suffix="元" onBlur={() => countTotal()} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="预付款支付状态" name="prepaymentsPayStatus">
                                                <Select disabled style={{ width: '100%' }} placeholder="预付款支付状态" filterOption={false}>
                                                    <Option value="unpaid" key="unpaid">
                                                        未支付
                                                    </Option>
                                                    <Option value="paid" key="paid">
                                                        已支付
                                                    </Option>
                                                    <Option value="refunding" key="refunding">
                                                        退款中
                                                    </Option>
                                                    <Option value="wx_refunding" key="wx_refunding">
                                                        微信退款中
                                                    </Option>
                                                    <Option value="refunded" key="refunded">
                                                        已退款
                                                    </Option>
                                                    <Option value="refund_reject" key="refund_reject">
                                                        退款驳回
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="应收金额" name="orderTotalAmount">
                                                <Input placeholder="0" disabled prefix="¥" suffix="元" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item
                                                label="折扣"
                                                name="orderDiscount"
                                                rules={[
                                                    {
                                                        pattern: /^[1-9]?(\.\d)?$/,
                                                        message: '请输入数字且可保留一位小数',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="请输入折扣"
                                                    onBlur={() => countTotal()}
                                                    disabled={isLook(mode) || orderTypeIsThree || isOrderPricePay}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item
                                                label="优惠金额"
                                                name="preferentialAmount"
                                                rules={[
                                                    {
                                                        pattern: /^\d+(\.\d{0,2})?$/,
                                                        message: '请输入数字且可保留二位位小数',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="0"
                                                    prefix="¥"
                                                    suffix="元"
                                                    onBlur={() => countTotal()}
                                                    onKeyUp={preferentialAmountInputKeyUp}
                                                    disabled={isLook(mode) || orderTypeIsThree || isOrderPricePay}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} xxl={4}>
                                            <Form.Item label="实收金额" name="orderActualAmount">
                                                <Input placeholder="自动计算" disabled prefix="¥" suffix="元" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="支出金额描述" name="orderPayoutDesc">
                                                <Input
                                                    placeholder="支出金额描述"
                                                    disabled={isLook(mode) || orderInfoOne.status === 'completed' || orderInfoOne.status === 'cancelled'}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item
                                                label="支出金额"
                                                name="orderPayoutAmount"
                                                rules={[
                                                    {
                                                        pattern: /^\d+(\.\d{0,2})?$/,
                                                        message: '请输入数字且可保留二位位小数',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="支出金额"
                                                    prefix="¥"
                                                    suffix="元"
                                                    disabled={isLook(mode) || orderInfoOne.status === 'completed' || orderInfoOne.status === 'cancelled'}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab={`${orderInfoOne.teamName ? '团队信息(' + orderInfoOne.teamName + ')' : '团队信息'}`} key="4">
                                    <Row>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="团队名称" name="teamId">
                                                <Select
                                                    disabled
                                                    style={{ width: '100%' }}
                                                    placeholder="请选择团队"
                                                    filterOption={false}
                                                    // showSearch
                                                    // allowClear
                                                    // notFoundContent={teamInfo.fetching ? <Spin size="small" /> : null}
                                                    // onSearch={teamQuerys}
                                                    // onFocus={() => teamQuery()}
                                                >
                                                    {teamInfo.data.map((item) => (
                                                        <Option value={item.value} key={item.value}>
                                                            {item.text}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>{' '}
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="服务车辆" name="teamVehicleCode">
                                                <Input placeholder="工单派工后可展示服务车辆" disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="主维修人" name="mainWeiXiuRenYuan">
                                                <Input placeholder="工单派工后可展示维修人员" disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="维修人员" name="weiXiuRenYuan">
                                                <Input placeholder="工单派工后可展示维修人员" disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xxl={4}>
                                            <Form.Item label="联系电话" name="teamMonitorPhone">
                                                <Input placeholder="工单派工后可展示联系电话" disabled />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab={`维修历史`} key="5">
                                    <RepairHistory
                                        choosedRepairHistoryOk={choosedRepairHistoryOk}
                                        isLook={isLook(mode)}
                                        orderStatus={orderStatus}
                                        vehicleId={orderForm.getFieldValue('customerVehicleId')}
                                        customerId={orderForm.getFieldValue('customerId')}
                                    ></RepairHistory>
                                </TabPane>
                                {/* <TabPane tab={`维修模版`} key="6">
                                    <RepairTemplate
                                        choosedRepairTemplateOk={choosedRepairTemplateOk}
                                        isLook={isLook(mode)}
                                        orderStatus={orderStatus}
                                    ></RepairTemplate>
                                </TabPane> */}
                                <TabPane tab={`维保任务书`} key="7">
                                    <TaskBook orderId={orderForm.getFieldValue('id')} vehicleCode={orderForm.getFieldValue('customerVehicleCode')}></TaskBook>
                                </TabPane>
                                <TabPane tab={`采埃孚信息`} key="8" forceRender={true}>
                                    <CaiAiFu orderInfoOne={orderInfoOne} mode={mode} caiAiFuRef={caiAiFuRef} />
                                </TabPane>
                            </Tabs>
                            <Row>
                                <Form.Item hidden name="customerVehicleCode">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="repairContactName">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="repairContactPhone">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="id">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="billId">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="repairLng">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="repairLat">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="ciBusinessEntityName">
                                    <Input />
                                </Form.Item>
                            </Row>
                        </div>
                    </div>
                    <div className="list-container" style={{ display: actTabs == '1' || actTabs == '3' || actTabs == '4' ? 'block' : 'none' }}>
                        <div className="form-details-warp" style={{ padding: '10px 20px 0 0px' }}>
                            <Row>
                                <Col span={5}>
                                    <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} label="预约时间" name="reservationTime">
                                        <DatePicker
                                            disabled
                                            placeholder="自动生成"
                                            className="pub-select"
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                            }}
                                            disabledDate={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} label="预计到达时间" name="teamEstimatedArrivalTime">
                                        <DatePicker
                                            disabled
                                            placeholder="自动生成"
                                            className="pub-select"
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                            }}
                                            disabledDate={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} label="预计完工时间" name="teamEstimatedFinishTime">
                                        <DatePicker
                                            disabled
                                            placeholder="自动生成"
                                            className="pub-select"
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                            }}
                                            disabledDate={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} label="进场时间" name="entryFactoryTime">
                                        <DatePicker
                                            placeholder="自动生成"
                                            className="pub-select"
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                            }}
                                            disabled={isLook(mode) || orderInfoOne.status === 'completed' || orderInfoOne.status === 'cancelled'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} label="出厂时间" name="outFactoryTime">
                                        <DatePicker
                                            placeholder="自动生成"
                                            className="pub-select"
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                            }}
                                            disabled={isLook(mode) || orderInfoOne.status === 'completed' || orderInfoOne.status === 'cancelled'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5} hidden={actTabs === '8'}>
                                    <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} label="运行小时数" name="operatingHours">
                                        <Input
                                            placeholder="请输入运行小时数"
                                            disabled={isLook(mode) || orderInfoOne.status === 'completed' || orderInfoOne.status === 'cancelled'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={actTabs === '8'}>
                                    <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} label="待修项目" name="tobeRepairedItems">
                                        <Input
                                            allowClear
                                            autoSize={true}
                                            placeholder="请输入待修项目"
                                            disabled={isLook(mode) || orderInfoOne.status === 'completed' || orderInfoOne.status === 'cancelled'}
                                        />
                                    </Form.Item>
                                </Col>
                                {/* <Col span={5}>
                                <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} label="维修时间" name="repairTime">
                                    <DatePicker
                                        disabled={isLook(mode)}
                                        className="pub-select"
                                        format="YYYY-MM-DD HH:mm"
                                        showTime={{ format: "HH:mm" }}
                                    />
                                </Form.Item>
                            </Col> */}
                                <Col span={9} hidden={actTabs === '8'}>
                                    <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} label="备注" name="remark">
                                        <Input.TextArea allowClear autoSize={true} placeholder="请输入备注" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!orderInfoOne.processResult}>
                                    <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} label="处理结果" name="processResult">
                                        <Input.TextArea allowClear autoSize={true} placeholder="请输入处理结果" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!orderInfoOne.orderCancelReason}>
                                    {orderInfoOne.orderCancelReason && orderInfoOne.orderCancelReason.indexOf('041') > -1 && (
                                        <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} label="作废原因" name="orderCancelReason">
                                            <Select disabled={isLook(mode)} allowClear placeholder="请选择服务方式" style={{ width: '100%' }}>
                                                {getPcodeDict('041').children.map((item) => (
                                                    <Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    )}

                                    {orderInfoOne.orderCancelReason && orderInfoOne.orderCancelReason.indexOf('041') === -1 && (
                                        <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} label="作废原因" name="orderCancelReason">
                                            <Input.TextArea allowClear autoSize={true} placeholder="请输入作废原因" disabled={isLook(mode)} />
                                        </Form.Item>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Form>
                {mode !== 'insert' && orderType === '014002' && actTabs != '5' && actTabs != '7' && actTabs != '8' && (
                    <SanBaoList mode={mode} orderId={rowInfo.id} orderInfo={rowInfo} parentOrderType={rowInfo.orderType} orderType={orderType}></SanBaoList>
                )}
                {/* {orderType === '014006' && (
                    <CombinationList mode={mode} orderId={rowInfo.id} parentOrderType={rowInfo.orderType} orderType={orderType}></CombinationList>
                )} */}
                <div style={{ display: (orderType === '014001' || orderType === '014006') && actTabs != '5' && actTabs != '7' && actTabs != '8' ? 'block' : 'none' }}>
                    <Maintenance
                        maintenanceRef={maintenanceRef}
                        orderId={rowInfo.id}
                        mode={mode}
                        onReceivables={onReceivables}
                        parentOrderType={rowInfo.orderType}
                        dataType="shoufei"
                        style={{ display: 'none' }}
                    />

                    <Material
                        materialRef={materialRef}
                        orderId={rowInfo.id}
                        orderInfo={rowInfo}
                        mode={mode}
                        onReceivables={onReceivables}
                        parentOrderType={rowInfo.orderType}
                        teamId={rowInfo.teamId}
                        dataType="shoufei"
                    />
                    <OtherProJect
                        otherProjectRef={otherProjectRef}
                        orderId={rowInfo.id}
                        mode={mode}
                        onReceivables={onReceivables}
                        parentOrderType={rowInfo.orderType}
                    />
                </div>
                <div className="list-container" style={{ position: 'fixed', left: '205px', bottom: 0, width: 'calc(100% - 240px)', zIndex: 999,  display: actTabs == '1' || actTabs == '3' || actTabs == '4' ? 'block' : 'none' }}>
                    <div className="form-details-warp bottom-info" style={{ padding: '10px 20px' }}>
                        <span>开单时间：{orderInfoOne.createTime || '暂无信息'}</span>
                        <span>开单员：{orderInfoOne.billName || '暂无信息'}</span>
                        <span>创建人：{`${OperatorUserType[orderInfoOne.createUserType]}--${orderInfoOne.createUserName}` || '暂无信息'}</span>
                        <span>审核人：{orderInfoOne.reviewerName || '暂无信息'}</span>
                        <span>审核时间：{orderInfoOne.reviewerTime ? moment(orderInfoOne.reviewerTime).format('YYYY-MM-DD HH:mm:ss') : '暂无信息'}</span>
                        <span>经营实体审核人：{orderInfoOne.ciBusinessEntityReviewerName || '暂无信息'}</span>
                        {orderInfoOne.repairTime && (
                            <span>维修时间：{orderInfoOne.repairTime ? moment(orderInfoOne.repairTime).format('YYYY-MM-DD HH:mm:ss') : '暂无信息'}</span>
                        )}
                        {/* <span>作废人：{orderInfoOne.reviewerName || "暂无信息"}</span>
                    <span>作废时间：{orderInfoOne.reviewerTime ? moment(orderInfoOne.reviewerTime).format("YYYY-MM-DD HH:mm:ss") : "暂无信息"}</span> */}
                        {orderInfoOne.orderStatus === 'cancelled' && (
                            <>
                                <span>取消人：{orderInfoOne.orderCancelUserName || '暂无信息'}</span>
                                <span>取消时间：{orderInfoOne.orderCancelTime || '暂无信息'}</span>
                            </>
                        )}
                    </div>
                </div>
                {mapVisible && (
                    <InsertAddressMap
                        visible={mapVisible}
                        onCloseMap={() => setMapVisible(false)}
                        saveAddressDetail={saveAddressDetail}
                        isLook={isLook(mode)}
                        defaultInfo={{
                            lat: saveMapAddr.latitude,
                            lng: saveMapAddr.longitude,
                        }}
                    />
                )}

                {modifyContactName && (
                    <>
                        <ModifyContactName
                            visible={modifyContactName}
                            onCancel={(bool) => setModifyContactName(bool)}
                            orderId={rowInfo && rowInfo.id}
                            customerUserId={orderForm.getFieldValue('customerUserId')}
                            repairContactName={orderForm.getFieldValue('repairContactName')}
                            handleSuccess={(name, id) => {
                                modifcustomerUserName(name, id)
                            }}
                        />
                    </>
                )}
                {orderEditCustomerVisible && (
                    <>
                        <OrderCustomerEdit
                            visible={orderEditCustomerVisible}
                            onCancel={(bool) => setOrderEditCustomerVisible(bool)}
                            orderDetailsInfo={orderInfoOne}
                            orderVehicleInfo={selectVehicle}
                            handleSuccess={(obj) => editCustomerSuccess(obj)}
                        />
                    </>
                )}
                {orderAddCustomerVisible && (
                    <>
                        <OrderCustomerAdd visible={orderAddCustomerVisible} onCancel={(bool) => setOrderAddCustomerVisible(bool)} />
                    </>
                )}
                {addVehicleRecordVisible && (
                    <AddVehicleRecord
                        visible={addVehicleRecordVisible}
                        setVisible={setAddVehicleRecordVisible}
                        onCancel={(bool) => setAddVehicleRecordVisible(bool)}
                        rowInfo={{ ...selectVehicle, id: selectVehicle && selectVehicle.value }}
                        mode="edit"
                    ></AddVehicleRecord>
                )}
                {/* 生成维修模版 */}
                <RepairTemplateDetail mode="insert" rawData={repairTemplateData} visible={showRepairTemplateDetail} setVisible={setShowRepairTemplateDetail} />
                {mode !== 'insert' && <ImgModal modalData={imgLookData} setModalData={setImgLookData} orderId={rowInfo && rowInfo.id} />}
                {mode !== 'insert' && (
                    <>
                        <LookCommentAndPayment
                            orderInfo={rowInfo}
                            lookVisible={lookVisible}
                            onLookClose={() => setLookVisible({ visible: false, taskMode: '' })}
                        />
                        <OrderDispatch
                            rowInfo={rowInfo}
                            visible={showOrderDispatchVisible}
                            setVisible={setShowOrderDispatchVisible}
                            onSuccess={() => {
                                orderDetailsLoad()
                            }}
                        ></OrderDispatch>
                        {reviewVisible && (
                            <ReviewOrder visible={reviewVisible} setVisible={setReviewVisible} onSuccess={orderDetailsLoad} orderId={rowInfo.id} />
                        )}
                        <ReviewOrdersModal
                            visible={reviewCancelVisible}
                            setVisible={setReviewCancelVisible}
                            onSuccess={() => {
                                isMode()
                            }}
                            reviewId={orderInfoOne.reviewId}
                        />
                    </>
                )}
            </Provider>
            <SettleDrawer mode="look" orderDetail={orderInfoOne} />
        </>
    )
}
export default OrderDetails
