import { message } from 'antd'
import moment from 'moment'
import BigNumber from 'bignumber.js'

//返回日期格式为YYYY-MM-DD HH:mm
export function momentFormat(text) {
    return text ? moment(text).format('YYYY-MM-DD HH:mm') : text
}

/**
 *根据要查找的key返回这一项 （递归查找）
 * @param {Array}  array 要查找的源数组
 * @param {string} code   唯一标识
 * @param {string} key   要查找的键名， 和code对应的  默认id
 * @param {Boolean} filter   查找是否过滤掉不要在左侧菜单展示的页面
 */

export function forFind(array, code, key = 'id', filter = false) {
    for (const item of array) {
        if (item[key] === code && (filter ? item.isShow : true)) {
            return item
        }
        if (item.children && item.children.length > 0) {
            let forFindRet = forFind(item.children, code, key, filter)
            if (forFindRet) {
                return forFindRet
            }
        }
    }
}

//re-from 错误校验提示信息方法
export function getFormValidatorErrText(error) {
    let { errorFields } = error
    for (let key in errorFields) {
        if (errorFields[key]?.errors?.length > 0) {
            return errorFields[key]?.errors[0]
        }
    }
    return '未知问题!'
}

/**
 * 数组里面（过滤数组对象id为重复的）
 *    let arr = [
            { id: "11", value: 11 },
            { id: "11", value: 11 },
            { id: "22", value: 22 },
        ]
      console.log(filterArrId(arr)) 
 */

export function filterArrId(arr) {
    let obj = {}
    let newArr = []
    arr.forEach((item) => {
        if (!obj[item.id]) {
            newArr.push(item)
            obj[item.id] = true
        }
    })
    return newArr
}

/**
 * 判断对象的值是不是全为空
 */
export function objectValueIsEmpty(object) {
    var isEmpty = true //值全为空
    Object.keys(object).forEach(function (x) {
        if (object[x] != null && object[x] != '') {
            isEmpty = false
        }
    })
    return isEmpty
}

/**
 * 判断是否是对象
 */
export function isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]'
}

// 判断两个对象(包括数组)是否相等
export function isObjectEqual(a, b) {
    let aProps = Object.getOwnPropertyNames(a)
    let bProps = Object.getOwnPropertyNames(b)
    if (aProps.length != bProps.length) {
        return false
    }
    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i]
        let propA = a[propName]
        let propB = b[propName]
        if (typeof propA === 'object') {
            if (!isObjectEqual(propA, propB)) {
                return false
            }
        } else if (propA !== propB) {
            return false
        }
    }
    return true
}

/**
 * 递归去除参数的前后空格
 */
export const trimParams = (data) => {
    if (typeof data === 'string') return data.trim()
    if (data && typeof data === 'object') {
        for (let key in data) {
            data[key] = trimParams(data[key])
        }
    }
    return data
}

/**
 * 数字补零   返回num字符串
 */
// export function formatNum(num) {
//     if (num === undefined || num === null || isNaN(+num)) {
//         return '0.00'
//     }
//     if (typeof num === 'number') {
//         num = String(num)
//     }
//     debugger
//     num = num.replace(/[^0-9|\.]/g, '') //清除字符串中的非数字非.字符

//     if (/^0+/)
//         //清除字符串开头的0
//         num = num.replace(/^0+/, '')
//     if (!/\./.test(num))
//         //为整数字符串在末尾添加.00
//         num += '.00'
//     if (/^\./.test(num))
//         //字符以.开头时,在开头添加0
//         num = '0' + num
//     num += '001' //在字符串末尾补零
//     debugger
//     return (+num).toFixed(2)
// }

export function formatNum(num) {
    // 检查输入是否有效
    if (num === undefined || num === null || isNaN(num)) {
        return '0.00';
    }

    // 将输入转换为字符串并处理特殊情况
    let numStr = String(num);
    if (numStr === '0') {
        return '0.00';
    }

    // 清除字符串中的非数字和非.字符，但保留负号和一个小数点
    numStr = numStr.replace(/[^-0-9.]/g, '');

    // 无效输入处理
    if (numStr === '' || numStr === '-' || numStr === '.') {
        return '0.00';
    }

    // 去除前导零（除非是数字0本身或者小数点开头的数字）
    if (!numStr.startsWith('-') && !numStr.startsWith('.') && !numStr.startsWith('0.')) {
        numStr = numStr.replace(/^0+/, '');
    }

    // 查找小数点的位置
    const dotIndex = numStr.indexOf('.');

    // 如果没有小数点，则添加".00"
    if (dotIndex === -1) {
        return numStr + '.00';
    }

    // 获取小数点后的部分
    let decimalPart = numStr.slice(dotIndex + 1);

    // 如果小数点后不足两位，则补零至两位
    if (decimalPart.length < 2) {
        decimalPart = decimalPart.padEnd(2, '0');
    } else {
        // 如果小数点后超过两位，则截断至两位
        decimalPart = decimalPart.slice(0, 2);
    }

    // 返回格式化的字符串
    return numStr.slice(0, dotIndex + 1) + decimalPart;
}

export function formatNumFixed(num, fixed) {
    if (num === undefined || num === null || isNaN(+num)) {
        return '0.00'
    }
    if (typeof num === 'number') {
        num = String(num)
    }

    num = num.replace(/[^0-9|\.]/g, '') //清除字符串中的非数字非.字符

    if (/^0+/)
        //清除字符串开头的0
        num = num.replace(/^0+/, '')
    if (!/\./.test(num))
        //为整数字符串在末尾添加.00
        num += '.00'
    if (/^\./.test(num))
        //字符以.开头时,在开头添加0
        num = '0' + num
    num += '001' //在字符串末尾补零
    return (+num).toFixed(fixed)
}

// 例如：
// 12345格式化为12,345.00
// 12345.6格式化为12,345.60
// 12345.67格式化为 12,345.67
// 只留两位小数。
// 回来后写了个格式化函数。可以控制小数位数，自动四舍五入。 代码如下：
export function fmoney(s, n) {
    n = n > 0 && n <= 20 ? n : 2
    s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + ''
    var l = s.split('.')[0].split('').reverse(),
        r = s.split('.')[1]
    let t = ''
    l.forEach((item, index) => {
        t += l[index] + ((index + 1) % 3 == 0 && index + 1 != l.length ? ',' : '')
    })
    // for (i = 0; i < l.length; i++) {
    //     t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
    // }
    return t.split('').reverse().join('') + '.' + r
}

/**
 * 整数数字转换  返回num字符串
 */
export function formatInt(num, contrast = null, msg = '错误警告') {
    if (num === undefined || num === null || isNaN(+num)) {
        return '0'
    }
    num = Math.abs(+num)
    //对比数值， 不对返回错误
    if (typeof contrast === 'number' && contrast < +num) {
        message.warning(msg)
        return '0'
    }
    return `${num}`.split('.')[0]
}
/**
 * 整数数字转换  返回num字符串
 */
export function formatDiscount(num) {
    if (num === undefined || num === null || isNaN(+num)) {
        return '0'
    }
    num = `${num}`
    if (/^0\.[0-9]{1,2}$|^0{1}$|^1{1}$|^1\.[0]{1,2}$/.test(num)) {
        return num
    } else {
        return '0'
    }
}

/**
 * 生成指定区间的随机整数
 */
export function randomNum(min, max) {
    return Math.floor(Math.random() * (max - min) + min)
}

//阿拉伯数字转换成大写汉字
export function numberParseChina(money) {
    //汉字的数字
    var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
    //基本单位
    var cnIntRadice = ['', '拾', '佰', '仟']
    //对应整数部分扩展单位
    var cnIntUnits = ['', '万', '亿', '兆']
    //对应小数部分单位
    var cnDecUnits = ['角', '分', '毫', '厘']
    //整数金额时后面跟的字符
    var cnInteger = '整'
    //整型完以后的单位
    var cnIntLast = '圆'
    //最大处理的数字
    var maxNum = 999999999999999.9999
    //金额整数部分
    var integerNum
    //金额小数部分
    var decimalNum
    //输出的中文金额字符串
    var chineseStr = ''
    //分离金额后用的数组，预定义
    var parts
    if (money === '') return ''
    money = parseFloat(money)
    if (money >= maxNum) {
        //超出最大处理数字
        return ''
    }
    if (money === 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger
        return chineseStr
    }
    //转换为字符串
    money = money.toString()
    if (money.indexOf('.') === -1) {
        integerNum = money
        decimalNum = ''
    } else {
        parts = money.split('.')
        integerNum = parts[0]
        decimalNum = parts[1].substr(0, 4)
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0
        var IntLen = integerNum.length
        for (var i = 0; i < IntLen; i++) {
            var n = integerNum.substr(i, 1)
            var p = IntLen - i - 1
            var q = p / 4
            var m = p % 4
            if (n == '0') {
                zeroCount++
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0]
                }
                //归零
                zeroCount = 0
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
            }
            if (m == 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q]
            }
        }
        chineseStr += cnIntLast
    }
    //小数部分
    if (decimalNum != '') {
        var decLen = decimalNum.length >= 2 ? 2 : decimalNum.length
        for (var i = 0; i < decLen; i++) {
            var n = decimalNum.substr(i, 1)
            if (n != '0') {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i]
            }
        }
    }
    if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger
    } else if (decimalNum == '') {
        chineseStr += cnInteger
    }
    return chineseStr
}

//获取随机字符串
export function getRandomStr(length = 32) {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let maxPos = chars.length
    let _string = ''
    for (let i = 0; i < length; i++) {
        _string += chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return _string
}

// 针对维修工单可以开负数的情况 计算公式
export function repairFormatNum(num) {
    if (num === undefined || num === null || isNaN(+num)) {
        return '0.00'
    }
    if (typeof num === 'number') {
        num = String(num)
    }

    num = num.replace(/[^0-9|\.\-]/g, '') //清除字符串中的非数字非.字符

    if (/^0+/)
        //清除字符串开头的0
        num = num.replace(/^0+/, '')
    if (!/\./.test(num))
        //为整数字符串在末尾添加.00
        num += '.00'
    if (/^\./.test(num))
        //字符以.开头时,在开头添加0
        num = '0' + num
    num += '001' //在字符串末尾补零
    // let newNum = num.match(/\d+\.\d{3}/);
    // return newNum ? (+newNum[0]).toFixed(2) : '0.00';
    // if (+num <= 0) {
    //     return `-${(+num).toFixed(2)}`
    // } else {
    return (+num).toFixed(2)
    // }
}

/**
 * 小数类型数字转换  返回num字符串
 */
export function formatIntPoint(num, contrast, msg = '错误警告') {
    if (num === undefined || num === null || isNaN(+num)) {
        return '1'
    }
    //对比数值， 不对返回错误
    if (!(contrast === undefined || contrast === null) && +contrast < +num) {
        message.warning(msg)
        return '0'
    }
    return `${num}`
}

/**
 * 小数类型数字转换  返回num字符串
 */
export function formatIntPoint1(num, contrast = null, msg = '错误警告') {
    if (num === undefined || num === null || isNaN(+num)) {
        return '1'
    }
    //对比数值， 不对返回错误
    if (!(contrast === undefined || contrast === null) && +contrast > +num) {
        message.warning(msg)
        return contrast + ''
    }
    return `${num}`
}

/**
 * 数字字符串转换数字
 */
export function switchNum1(val, initNum) {
    if (val && typeof val === 'string' && !isNaN(+val)) {
        return val
    }
    return initNum ?? '0'
}

//合并打印单中配件的相同值合并，数量金额叠加 方法2
export const mergeRepeatArr = async (arr, singleTotal = 'amount') => {
    const mergeRepeatList = []
    await arr?.forEach((item) => {
        let findIndex = mergeRepeatList.findIndex(
            (newItem) =>
                newItem.materialCode == item.materialCode &&
                newItem.materialName == item.materialName &&
                newItem.shelfCode == item.shelfCode &&
                newItem.discountRate == item.discountRate &&
                newItem.price == item.price
        )
        if (findIndex === -1) {
            mergeRepeatList.push(item)
        } else {
            let newNum = +formatNum(Number(item.num) + Number(mergeRepeatList[findIndex].num))
            //mergeRepeatList[findIndex] = { ...item, num: newNum, [singleTotal]: +formatNum(newNum * item.price * (item.discountRate / 100)) }
            mergeRepeatList[findIndex] = {
                ...item,
                num: newNum,
                [singleTotal]: +bigNumber.toFixed(bigNumber.multiTimes(newNum, item.price, item.discountRate / 100), 2),
            }
        }
    })
    return mergeRepeatList
}
//计算
export const bigNumber = {
    // 加
    add(x, y) {
        x = BigNumber(x).toFixed(2)
        y = BigNumber(y).toFixed(2)
        return BigNumber(x).plus(y).toNumber()
    },
    // 减
    minus(x, y) {
        x = BigNumber(x).toFixed(2)
        y = BigNumber(y).toFixed(2)
        return BigNumber(x).minus(y).toNumber()
    },
    // 乘
    times(x, y) {
        x = BigNumber(x)
        y = BigNumber(y)
        return x.times(y).toNumber()
    },
    // 除
    dividedBy(x, y) {
        x = BigNumber(x)
        y = BigNumber(y)
        return x.dividedBy(y).toNumber()
    },
    // 多个相加
    multiAdd(...params) {
        let num = BigNumber(0)
        params.forEach((x) => (num = num.plus(BigNumber(x))))
        return num.toNumber()
    },
    // 多个相乘
    multiTimes(...params) {
        let num = BigNumber(1)
        params.forEach((x) => (num = num.times(BigNumber(x))))
        return num.toNumber()
    },
    //保留小数
    toFixed(num, x) {
        return BigNumber(num).toFixed(x)
    },
}

export const trasDsToTreeData = (ds, keyField, titleField) => {
    return ds.map((el) => {
        const key = el[keyField]
        const title = el[titleField]
        const children = el.children && el.children.length > 0 ? trasDsToTreeData(el.children, keyField, titleField) : undefined
        // const disabled = el.enable === 0;
        return {
            el,
            key,
            title,
            // disabled,
            value: el['code'],
            // selectable: !disabled,
            children,
        }
    })
}
