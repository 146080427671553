import React from 'react'
import { Button } from 'antd'
import { DataTable } from './DataTable'
import { YhBox } from '@/erp_subpackage/components/YhBox'
import { useStockPoDetailModel } from '../backlogModel'

export const DataBox = () => {
    const { exportOut, types, setType, resetSearchParam, loadData } = useStockPoDetailModel()

    const action = (
        <>
            <div style={{ marginBottom: '10px' }}>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={() => {
                        setType(types.all)
                        resetSearchParam();
                        loadData({ pageSize: 15, current: 1 }, 'all', {})
                    }}
                >
                    汇总
                </Button>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={() => {
                        setType(types.detail)
                        resetSearchParam()
                        loadData({ pageSize: 15, current: 1 }, 'detail', {})
                    }}
                >
                    明细
                </Button>
            </div>
            <Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={() => {
                    exportOut()
                }}
            >
                导出
            </Button>
        </>
    )

    return (
        <YhBox title={action}>
            <DataTable />
        </YhBox>
    )
}
