import { useState, useEffect } from 'react'
import { createModel } from 'hox'
import { backlogService} from './backlogService'
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { convertRes2Blob } from '@/erp_subpackage/utils/exportFile'


export const useStockPoDetailModel = createModel(function () {
    const {
        user: { shopId },
        setLoadingInfo,
    } = useGlobalModel()
    const initPgCfg = { pageSize: 15, current: 1 }

    const types = {
        all: 'all',
        detail: 'detail',
    }

    //查询条件
    const [searchParam, setSearchParam] = useState({})
    //列表数据源
    const [dataSource, setDataSource] = useState([])
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState()
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState(initPgCfg)
    //列表统计展示数据
    const [loading, setLoading] = useState(false)
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const [type, setType] = useState(types.all)

    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //占用库存可查明细 弹框
    const [stockQueryModal, setStockQueryModal] = useState(null)

    //切换门店重置缓存
    useEffect(() => {
        setSearchParam({})
    }, [shopId])

    //重置
    const resetSearchParam = () => {
        setSearchParam({})
    }

    const loadData = async (page = initPgCfg, searchType,searchValue = searchParam) => {
        setLoading(true)
        const searchParams = {
            searchItem:{
                shopId,
                ...searchValue,
                bussCode: searchValue?.bussCode?.trim() ?? undefined,
                materialCode: searchValue?.materialCode?.trim() ?? undefined,
                materialName: searchValue?.materialName?.trim() ?? undefined,
                replaceCode: searchValue?.replaceCode?.trim() ?? undefined,
                shelfCode: searchValue?.shelfCode?.trim() ?? undefined,
            },
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? initPgCfg.current,
        }
        searchType = searchType ?? type;
        let { retData } = searchType === types.all ? await backlogService.pageAll(searchParams) : await backlogService.pageDetail(searchParams)
        const { list, ...pg } = retData
        setPgCfg(transPgToPagination(pg))
        setDataSource(list)
        setLoading(false)
    }

    //导出
    const exportOut = async (searchValue = searchParam) => {
        setLoadingInfo({ loadingVisible: true, txt: '快速导出中' })
        try {
            const searchParams = {
                shopId,
                ...searchValue,
                pageSize: -1,
                pageNum: 1,
            }
            let result = type === types.all ?  await backlogService.backlogAllService(searchParams) :  await backlogService.backlogDetailService(searchParams)
            result && setLoadingInfo({ loadingVisible: false, txt: '' })
            convertRes2Blob(result)
        } catch {
            setLoadingInfo({ loadingVisible: false, txt: '' })
        }
    }

    return {
        /***field***/
        searchParam,
        loading,
        dataSource,
        pgCfg,
        /***method***/
        setDataSource,
        resetSearchParam,
        updateSearchParam,
        loadData,
        exportOut,
        selectedRow,
        setSelectedRow,
        selectedRowKeys,
        setSelectedRowKeys,
        setType,
        types,
        type,
        stockQueryModal,
        setStockQueryModal
    }
})
