import React, { useContext, useState, useEffect } from 'react'
import { Button, Dropdown, Input, Menu, Tooltip } from "antd";
import ListComponent from '@/components/listComponent'
import { getCustomerListNew } from '@/service/customerService'
import TopSearch from '@/components/TopSearch'
import ParintMadal from './printOrder' //打印弹框
import ReviewRecord from './orderReviewRecord' //审核记录弹框
import OrderPay from './orderPay' //平台结算框
import { getPcodeDict } from '@/utils/dataStorage'
import { MyContext } from '@/store/myContext'
import Sanbao from './sanbao'
import OrderLogs from './orderLogs'
import { getAllTable } from './table'
import SetTableSort from '@/components/SetTableSort/index'
import OrderDispatch from './orderDispatch'
import ReviewOrder from './reviewModal'
import { useOrderTableChange, useInit, useOrderTable, useOrderCodeClick, orderHandle } from './orderHooks'
import {handleSettleScaniaPrint, handleDispatchOrgan, handlePrint, exportOrderDetailed, exportOrder, butResourceApply, reviewRecord, handleDispatchOrder,snkyPrint } from './orderListCommon'
import SknyPrints from './sknyPrints' //打印弹框
import SettleScaniaPrints from './settleScaniaPrints' //打印弹框
import { DownOutlined } from "@ant-design/icons";

const saveKey = 'saveReviewOrderTableSort'

//工单列表
const OrdersManage = () => {
    const { dispatch, state, message, detailsPage, Option, showAllOrgan } = useContext(MyContext)
    // const [customerList, setCustomerList] = useState([]) //客户列表
    const [showSetTableSort, setShowSetTableSort] = useState(false)
    const [reviewInfo, setReviewInfo] = useState({
        visible: false,
        orderId: '',
    })
    const [orderPayInfo, setOrderPayInfo] = useState({
        visible: false,
        orderId: '',
    }) //结算
    const [printSnkyVisible, setPrintSnkyVisible] = useState({
        visible: false,
        orderId: '',
    })
    const [printSettleScaniaVisible, setPrintSettleScaniaVisible] = useState({
        visible: false,
        orderId: '',
    })
    const [showSanbao, setShowSanbao] = useState(false)
    const [choosedOrderId, setChoosedOrderId] = useState('')

    const [showLogs, setShowLogs] = useState(false)

    const [printVisible, setPrintVisible] = useState({
        visible: false,
        orderId: '',
    }) //打印

    //table 数据
    const [data, selectInfo, setSelectInfo, pagination, loading, loadData, searchValue, setSearchValue, onTableChange] = useOrderTable(state)
    //副标题计算
    const [subTitle, rowSelection] = useOrderTableChange(data, setSelectInfo)
    //初始化数据
    const handleClickOrderCode = (record) => {
        setSelectInfo({ rowInfo: record, rowIndex: data.find((item) => item.id === record.id) })
        setIsOrderCodeClick(true)
    }
    const [dictObj, org, allOrg, columns, initTableColumns] = useInit({ setShowSanbao, setChoosedOrderId, setShowLogs, saveKey, handleClickOrderCode })
    //点击订单编号
    const [setIsOrderCodeClick] = useOrderCodeClick(state, { selectInfo, dispatch, detailsPage, setPrintSettleScaniaVisible,setPrintSnkyVisible, setPrintVisible, setOrderPayInfo, dictObj })

    const [reviewVisible, setReviewVisible] = useState(false)

    useEffect(() => {
        if (state.activeMenu === 'reviewOrder' && !showSanbao) {
            loadData(pagination.current, searchValue)
        }
    }, [state, showSanbao]) // eslint-disable-line react-hooks/exhaustive-deps

    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: 'searchSelect',
                placeholder: '请选择客户名称',
                valueName: 'customerId',
                optionName: 'customerName',
                optionId: 'id',
                searchApi: getCustomerListNew,
                searchItemName: 'customerName'
            },
            {
                type: 'input',
                placeholder: '请输入客户名称',
                valueName: 'customerName',
            },
            {
                type: 'input',
                placeholder: '请输入工单编号',
                valueName: 'orderCode',
            },
            {
                type: 'input',
                placeholder: '请输入团队名称',
                valueName: 'teamName',
            },
            {
                type: 'input',
                placeholder: '请输入车辆牌照',
                valueName: 'customerVehicleCode',
            },
            {
                type: 'input',
                placeholder: '请输入车辆Vin',
                valueName: 'customerVehicleVin',
            },
            {
                hidden: !showAllOrgan,
                type: 'select',
                placeholder: '请选择客户所属机构',
                valueName: 'organId',
                optionList: () =>
                    allOrg.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.organName}
                        </Option>
                    )),
            },
            {
                type: 'select',
                placeholder: '请选择工单来源',
                valueName: 'orderSource',
                optionList: () =>
                    getPcodeDict('008').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
        ],
        hideList: [
            {
                type: 'select',
                placeholder: '请选择三包品牌',
                valueName: 'threeGuaranteesBrand',
                optionList: () =>
                    getPcodeDict('106').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'select',
                placeholder: '请选择工单类型',
                valueName: 'orderType',
                optionList: () =>
                    getPcodeDict('014').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'select',
                placeholder: '请选择故障分类',
                valueName: 'faultClassification',
                optionList: () =>
                    getPcodeDict('037').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'input',
                placeholder: '请输入主修人',
                valueName: 'majorUserName',
            },
            {
                type: 'select',
                placeholder: '请选择服务类型',
                valueName: 'serviceType',
                optionList: () =>
                    getPcodeDict('011').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'select',
                placeholder: '请选择服务方式',
                valueName: 'serviceMethod',
                optionList: () =>
                    getPcodeDict('012').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'input',
                placeholder: '请输入对接人',
                valueName: 'dockPeople',
            },
            {
                type: 'input',
                placeholder: '请输入报修用户',
                valueName: 'repairContactName',
            },
            {
                type: 'input',
                placeholder: '请输入联系电话',
                valueName: 'repairContactPhone',
            },
            {
                type: 'input',
                placeholder: '请输入开单员',
                valueName: 'billName',
            },
            {
                type: 'input',
                placeholder: '请输入索赔单号',
                valueName: 'claimNumber',
            },
            {
                type: 'RangePicker',
                valueName: ['startTime', 'endTime'],
                span: 16,
                xxl: 8,
            },
            {
                type: 'RangePicker',
                valueName: ['orderSettleTimeStart', 'orderSettleTimeEnd'],
                placeholder: ['结算时间', '结算时间'],
                span: 16,
                xxl: 8,
            }
        ],
    }

    //派单
    const [showOrderDispatchVisible, setShowOrderDispatchVisible] = useState(false)
    const PrintMenu = () => {
        return (
            <Menu>
                <Menu.Item key="2">
                    <span onClick={() => handlePrint(selectInfo, setPrintVisible)}>财务打印</span>
                </Menu.Item>
                <Menu.Item key="3">
                    <span onClick={() => handleSettleScaniaPrint(selectInfo, setPrintSettleScaniaVisible)}>结算打印</span>
                </Menu.Item>
                <Menu.Item key="0">
                    <span onClick={() => snkyPrint(selectInfo, setPrintSnkyVisible,1)}>工单打印</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => snkyPrint(selectInfo, setPrintSnkyVisible,0)}>工单无单价打印</span>
                </Menu.Item>
            </Menu>
        )
    }
    //列表button
    const topButtons = [
        butResourceApply('orderLook') && {
            click: () => orderHandle('look', state.activeMenu, { selectInfo, dispatch, detailsPage, setPrintVisible, setOrderPayInfo, dictObj }),
            content: '查看',
        },
        butResourceApply('orderEdit') && {
            click: () => orderHandle('edit', state.activeMenu, { selectInfo, dispatch, detailsPage, setPrintVisible, setOrderPayInfo, dictObj }),
            content: '编辑',
        },
        butResourceApply('dispatchOrgan') && {
            click: () => handleDispatchOrgan(selectInfo, allOrg, loadData, searchValue),
            content: '转派机构',
        },
        butResourceApply('orderEditTeamUser') && {
            click: () => handleDispatchOrder('modify', selectInfo, setShowOrderDispatchVisible),
            content: '修改维修人员',
        },
        butResourceApply('orderExport') && {
            click: () => exportOrder(columns, searchValue, state.activeMenu, rowSelection),
            content: '导出',
        },
        butResourceApply('orderExport') && {
            click: () => exportOrderDetailed(columns, searchValue, state.activeMenu, rowSelection),
            content: '导出明细',
        },
        // butResourceApply('orderPoint') && {
        //     click: () => handlePrint(selectInfo, setPrintVisible, rowSelection),
        //     content: '打印',
        // },
        // {
        //     click: () => snkyPrint(selectInfo, setPrintSnkyVisible),
        //     content: '工单打印',
        // },
        {
            dropdown:true,
            ButtonType: <Dropdown overlay={PrintMenu}>
            <Button shape="round" className="but-left">
            打印
                <DownOutlined />
            </Button>
        </Dropdown>
        },
        butResourceApply('orderAuditRecords') && {
            click: () => reviewRecord(selectInfo, setReviewInfo),
            content: '审核记录',
        },
        butResourceApply('orderReject') && {
            click: () => {
                if (!selectInfo.rowInfo.id) {
                    message.warning('请选择你要审核的工单')
                    return
                }
                setReviewVisible(true)
            },
            content: '审核',
        },
        {
            click: () => {
                setShowSetTableSort(true)
            },
            content: '设置table排序',
        },
    ]

    return (
        <>
            {/* {state.activeMenu === "reviewOrder" && ( */}
            {
                <>
                    <TopSearch
                        title="工单管理"
                        justify="start"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    {columns.length > 0 && (
                        <ListComponent
                            title="待审核工单列表"
                            buttons={topButtons}
                            loading={loading}
                            columns={columns}
                            dataSource={data}
                            pagination={pagination}
                            onTableChange={onTableChange}
                            selectInfo={selectInfo}
                            scroll={{ x: 'max-content' }}
                            setSelectInfo={(obj) => setSelectInfo(obj)}
                            rowSelection={rowSelection}
                            subTitle={subTitle}
                        />
                    )}
                </>
            }
            <OrderDispatch
                org={org}
                rowInfo={selectInfo.rowInfo}
                visible={showOrderDispatchVisible}
                setVisible={setShowOrderDispatchVisible}
                onSuccess={() => {
                    loadData(1, searchValue)
                }}
            ></OrderDispatch>
            {printVisible.visible && <ParintMadal visible={printVisible} onClose={(bool) => {setPrintVisible(bool);loadData(pagination.current, searchValue)}} />}
            {printSettleScaniaVisible.visible && <SettleScaniaPrints visible={printSettleScaniaVisible} onClose={(bool) => {setPrintSettleScaniaVisible(bool);loadData(pagination.current, searchValue)}}/>}
            {printSnkyVisible.visible && <SknyPrints visible={printSnkyVisible} onClose={(bool) => {setPrintSnkyVisible(bool);loadData(pagination.current, searchValue)}}  type={printSnkyVisible.type}/>}
            {reviewInfo.visible && (
                <ReviewRecord visible={reviewInfo.visible} orderId={reviewInfo.orderId} onClose={() => setReviewInfo({ visible: false, orderId: '' })} />
            )}
            {orderPayInfo.visible && (
                <OrderPay
                    visible={orderPayInfo.visible}
                    orderId={orderPayInfo.orderId}
                    onClose={() => setOrderPayInfo({ visible: false, orderId: '' })}
                    loadData={loadData}
                    searchValue={searchValue}
                />
            )}
            <Sanbao mode={'edit'} orderId={choosedOrderId} visible={showSanbao} setShowSanbe={setShowSanbao} />
            {/* 日志 */}
            <OrderLogs orderId={choosedOrderId} visible={showLogs} setShowLogs={setShowLogs} />
            {/* 设置排序 */}
            <SetTableSort
                visible={showSetTableSort}
                setShowTableSort={setShowSetTableSort}
                saveKey={saveKey}
                allData={getAllTable()}
                handleChangeColumns={initTableColumns}
            />
            {reviewVisible && (
                <ReviewOrder
                    visible={reviewVisible}
                    setVisible={setReviewVisible}
                    onSuccess={() => {
                        loadData(1, searchValue)
                    }}
                    orderId={selectInfo.rowInfo.id}
                />
            )}
        </>
    )
}
export default OrdersManage
