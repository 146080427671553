import React, { useContext, useEffect, useState, useRef } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { disableCustomer, getCustomerListNew, deleteCustomer, getUserList } from "@/service/customerService"
import { userPage } from "@/service/orgService"
import { MyContext } from "@/store/myContext"
import { getPcodeDict } from "@/utils/dataStorage"
import Statistics from "./statistics"
import { Select, Modal } from "antd"
import { organPage } from "@/service/orgService"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import Loading from "@/components/Loading"
import { OperatorUserType } from "@/utils/enum"
import YhTooltip from "@/components/YhTooltip"
import Log from './log'

const { Option } = Select

//客户管理
export default ({ organId }) => {
    const { dispatch, state, message, detailsPage, Switch, Option, showAllOrgan } = useContext(MyContext)
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const [customerData, setCustomerData] = useState(null) //列表
    const order = useRef({ column: "updateTime", rule: "DESC" })
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState({ queryTemporaryCustomer: 1, organId }) //搜索
    const [customerType, setCustomerType] = useState({})
    const [org, setOrg] = useState([])
    const [logId, setLogId] = useState(null)
    useEffect(() => {
        let obj = {}
        getPcodeDict("017").children.forEach((item) => {
            obj[item.dictCode] = item.title
        })
        setCustomerType(obj)
    }, [])
    useEffect(() => {
        if (state.activeMenu === "clientRecord") {
            loadData(pagination.current)
        }
        return () => setCustomerData(null)
    }, [state])

    useEffect(() => {
        getOrganPage()
    }, [])

    //获取机构列表
    const getOrganPage = () => {
        organPage({
            searchItem: { orderFlag: 1 },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            setOrg(res.retData.list)
        })
    }

    //默认加载
    const loadData = (current, search = searchValue) => {
        setLoading(true)
        console.log(search)
        let data = {
            searchItem: { organId, ...search },
            orders: [
                order.current
            ],
            startTime: search.startTime,
            endTime: search.endTime,
            pageNum: current,
            pageSize: 10,
        }
        getCustomerListNew(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                setCustomerData(res.retData.list)
                setLoading(false)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            .catch((err) => {
                setCustomerData([])
                setLoading(false)
                // message.error(err.retMsg)
            })
    }

    //分页事件
    // const onTableChange = async (page) => {
    //     await setpagination({
    //         ...pagination,
    //         current: page.current,
    //     })
    //     loadData(page.current, searchValue)
    // }

    //分页事件 置空当前行信息
    const onTableChange = async (page, filters, sorter) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        let orderC = { column: "updateTime", rule: "DESC" }
        if (sorter && sorter.field && sorter.order) {
            let rule = sorter.order === "ascend" ? "ASC" : "DESC"
            orderC = {
                column: sorter.field,
                rule,
            }
        }
        order.current = orderC
        loadData(page.current, searchValue)
    }

    //新增，编辑
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "客户新增",
            edit: "客户编辑",
            look: "客户查看",
            statis: "账户统计",
        }
        let modeKey = "clientRecord/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的行信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的行信息")
                return
            }
            //每次查看把之前的删除， 重新生成
            await dispatch({
                type: "removeTabs",
                targetKey: modeKey,
            })
        }
        if (mode === "statis") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择一个客户")
                return
            }
            await dispatch({
                type: "removeTabs",
                targetKey: modeKey,
            })
            await dispatch({
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: <Statistics rowInfo={selectInfo.rowInfo} />,
            })
        } else {
            await dispatch({
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.ClientDetails({
                    modeKey,
                    dispatch,
                    mode,
                    rowInfo: selectInfo.rowInfo,
                }),
            })
        }
    }
    //客户开关
    const clientSweitchChange = (checked, e, id) => {
        e.stopPropagation()
        disableCustomer(id, checked ? 0 : 1).then((res) => {
            message.success("操作成功")
            loadData(1)
        })
    }

    //table配置
    const columns = [
        {
            title: "客户编码",
            align: "center",
            dataIndex: "customerCode",
            width:300,
            render: (text) => <YhTooltip maxWidth={300} text={text}/>,
            sorter: true,
        },
        {
            title: "客户名称",
            align: "center",
            dataIndex: "customerName",
            width:300,
            render: (text) => <YhTooltip maxWidth={300} text={text}/>,
            sorter: true,
        },
        {
            title: "客户类型",
            align: "center",
            dataIndex: "customerType",
            render: (text) =>(text) => <YhTooltip maxWidth={300} text={customerType[text]}/> 
        },
        {
            title: "联系人",
            align: "center",
            dataIndex: "customerCorporation",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        },
        {
            title: "联系电话",
            align: "center",
            dataIndex: "customerCorporationPhone",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        },
        {
            title: "所属机构",
            align: "center",
            dataIndex: "organName",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        },
        {
            title: "所属团队",
            align: "center",
            dataIndex: "teamName",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        },
        {
            title: "是否大包",
            align: "center",
            dataIndex: "bigBagFlag",
            render: (text) =><YhTooltip maxWidth={300} text={text === 1 ? "是" : "否"}/>,
        },
        {
            title: "业务经理",
            align: "center",
            dataIndex: "businessManagerName",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        },
        {
            title: "联系地址",
            align: "center",
            dataIndex: "customerAddress",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        },
        {
            title: "创建人",
            align: "center",
            dataIndex: "createUserName",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        },
        {
            title: "创建人类型",
            align: "center",
            dataIndex: "createUserType",
            render: (text) => <YhTooltip maxWidth={300} text={OperatorUserType[text]}/>,
        },
        {
            title: "登记日期",
            align: "center",
            dataIndex: "createTime",
            sorter: true,
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "customerDesc",
        },
        {
            title: "操作日志",
            align: "center",
            dataIndex: "id",
            render: (text, record) => (
                <div
                    style={{ color: '#2e3aeb', cursor: 'pointer' }}
                    onClick={() => {
                       setLogId(text)
                    }}
                >
                    查看日志
                </div>
            ),
        },
        {
            title: "操作",
            align: "center",
            width: "12%",
            dataIndex: "customerStatus",
            render: (text, record) => (
                <Switch size="small" checked={text == 0 ? true : false} onChange={(checked, e) => clientSweitchChange(checked, e, record.id)} />
            ),
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "select",
                placeholder: "请选择客户类型",
                valueName: "customerType",
                optionList: () =>
                    getPcodeDict("017").children.map((item) => (
                        <Option key={item.id} value={item.dictCode}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: "input",
                placeholder: "请输入客户名称",
                valueName: "customerName",
            },
            {
                type: "input",
                placeholder: "请输入联系人",
                valueName: "customerCorporation",
            },
            {
                type: "input",
                placeholder: "请输入联系电话",
                valueName: "customerCorporationPhone",
            },
            {
                type: "searchSelect",
                placeholder: "请选择所属业务经理",
                valueName: "businessManager",
                optionName: "nickname",
                optionId: "id",
                searchItemName: "nickname",
                searchApi: userPage,
            },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
            {
                type: "select",
                placeholder: "请选择客户状态",
                valueName: "customerStatus",
                optionList: () => (
                    <>
                        <Option value={0} key={0}>
                            启用
                        </Option>
                        <Option value={1} key={1}>
                            禁用
                        </Option>
                    </>
                ),
            },
            {
                hidden: !showAllOrgan,
                type: "select",
                placeholder: "请选择客户所属机构",
                valueName: "organId",
                optionList: () =>
                    org.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.organName}
                        </Option>
                    )),
            },
            {
                type: "select",
                placeholder: "请选择是否大包",
                valueName: "bigBagFlag",
                optionList: () => (
                    <>
                        <Option value={0} key={0}>
                            否
                        </Option>
                        <Option value={1} key={1}>
                            是
                        </Option>
                    </>
                ),
            },
            {
                type: "Checkbox",
                valueName: "queryTemporaryCustomer",
                placeholder: "非临时用户",
                defaultChecked: searchValue.queryTemporaryCustomer ? true : false,
            },
           
        ],
    }

    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的客户信息")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除客户 - ${selectInfo.rowInfo.customerName}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                deleteCustomer(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current)
                })
            },
        })
    }
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("look"),
            content: "查看",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },

        // {
        //     click: () => handleDelete("edit"),
        //     content: "删除",
        // },

        {
            click: () => handleDetails("statis"),
            content: "账户统计",
        },
        {
            click: () => exportCustomer(),
            content: "导出",
        },
    ]

    const exportCustomer = async () => {
        setExportLoading(true)
        let data = {
            type: "post",
            data: {
                pageSize: pagination.pageSize,
                pageNum: pagination.current,
                startTime: searchValue.startTime ? searchValue.startTime : null,
                endTime: searchValue.endTime ? searchValue.endTime : null,
                searchItem: {
                    ...searchValue,
                },
                orders: [
                    order.current
                ],
            },
        }
        let resFlie = await download("/ci-customer/customer/export", data)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }

    return (
        <>
            {state.activeMenu === "clientRecord" && (
                <>
                    <TopSearch
                        title="客户管理"
                        justify="start"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                        defaultSearch={{ queryTemporaryCustomer: 1 }}
                    />
                    <ListComponent
                        title="客户列表"
                        buttons={topButtons}
                        loading={loading}
                        columns={columns}
                        dataSource={customerData}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        scroll={{ x: 2800 }}
                    />
                    {exportLoading && <Loading />}
                    {logId && <Log logId={logId} setLogId={setLogId} />}
                </>
            )}
        </>
    )
}
